import { Box, Button, Typography } from "@mui/material";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useState } from "react";
import Colors from "../CommonComponents/Colors";
import burgerImage from "./3-500x500.webp";
import chickenburgerImage from "./58824b33e81acb96424ffab9.png";
import chickennuggetImage from "./7761_BlueberryWaffles_LAYERED.webp";
import vegRiceImage from "./ai-generated-3d-rendering-of-a-fried-rice-in-a-plate-or-bowl-on-transparent-background-ai-generated-png.webp";
import frenchfriesImage from "./ai-generated-french-fries-with-dipping-sauce-on-a-transparent-background-ai-png.webp";
import choclateshakeImage from "./Chocolate-Milkshake.png";
import mojitoImage from "./CZ_Batch_7_CocktailCard_1000x1000_Mojito_Static (1).png.png";
import watermelonImage from "./glass-of-watermelon-juice-perfect-for-drink-catalog-ai-generated-png.webp";
import ombeletteImage from "./omelet-with-vegetables-png.webp";
import vegnoodlesImage from "./schezwan-noodles-or-szechuan-vegetable-png.webp";

const VegetableProducts = [
  {
    name: "Cheese Corn Burger",
    image: burgerImage,
    rate: "₹ 145",
  },
  {
    name: "French Fries",
    image: frenchfriesImage,
    rate: "₹ 80",
  },
  {
    name: "Veg Noodles (schezwan)",
    image: vegnoodlesImage,
    rate: "₹ 155",
  },
  {
    name: "Veg Fried Rice (schezwan)",
    image: vegRiceImage,
    rate: "₹ 165",
  },
  {
    name: "Crispy Chicken Burger",
    image: chickenburgerImage,
    rate: "₹ 175",
  },
  {
    name: "Omelette",
    image: ombeletteImage,
    rate: "₹ 25",
  },
  {
    name: "Chicken Nuggets",
    image: chickennuggetImage,
    rate: "₹ 99",
  },
  {
    name: "Watermelon Juice",
    image: watermelonImage,
    rate: "₹ 49",
  },
  {
    name: "Chocolate Milkshake",
    image: choclateshakeImage,
    rate: "₹ 139",
  },
  {
    name: "Ginger Lime Soda",
    image: mojitoImage,
    rate: "₹ 75",
  },
];

export default function VegContainPage() {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slideLeft = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? VegetableProducts.length - 4 : prev - 1
    );
  };

  const slideRight = () => {
    setCurrentSlide((prev) =>
      prev === VegetableProducts.length - 4 ? 0 : prev + 1
    );
  };

  return (
    <Box pt={"20px"} pb={"20px"} bgcolor={"#f6f4f4"}>
      <Box
        position="relative"
        width="100%"
        display="flex"
        justifyContent="center"
      >
        {/* Button to Slide Left */}
        <Button
          onClick={slideLeft}
          sx={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <ArrowBackIosIcon />
        </Button>

        {/* Slider Container */}
        <Box
          display="flex"
          gap={"20px"}
          width={"100%"}
          sx={{
            transition: "transform 0.5s ease",
            transform: `translateX(-${currentSlide * 300}px)`,
          }}
        >
          {VegetableProducts.map((item) => (
            <Box
              key={item.name}
              width={"280px"}
              bgcolor={"white"}
              borderRadius={"20px"}
              pb={"30px"}
              flexShrink={0}
              display="flex" // Use flexbox for alignment
              flexDirection="column" // Stack items vertically
              alignItems="center" // Center items horizontally
              justifyContent="space-between" // Center items vertically
              sx={{
                transition: "all 0.3s ease-in-out", // Move transition to sx
                textAlign: "center", // Center text alignment
                padding: "20px", // Add padding for spacing
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mb={"10px"} // Margin bottom to separate image and text
              >
                <img src={item.image} alt={item.name} width={"150px"} />
              </Box>
              <Box>
                <Typography
                  fontWeight={600}
                  fontSize={"18px"}
                  color={Colors.BLACK}
                >
                  {item.name}
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={"18px"}
                  color={Colors.BLACK}
                >
                  {item.rate}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        {/* Button to Slide Right */}
        <Button
          onClick={slideRight}
          sx={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <ArrowForwardIcon />
        </Button>
      </Box>
    </Box>
  );
}
