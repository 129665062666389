import Service from "./Service";
import ServiceGrid from "./ServiceGrid";
import assets from "../../assets";
import routes from "../../routes/routes";
import turf1 from "./TurfSliderImg/cricket1.jpg";
import turf2 from "./TurfSliderImg/cricket2.jpg";
import turf3 from "./TurfSliderImg/woodball1.avif";
import turf4 from "./TurfSliderImg/woodball2.png";

// import turfImages from "../../assets/TurfImages";

const { "Turf_logo.svg": Turf_logo } = assets;
// const {
//   "turf1.jpg": TurfSlide1,
//   "turf2.jpg": TurfSlide2,
//   "turf3.jpg": TurfSlide3,
//   "turf4.jpg": TurfSlide4,
// } = turfImages;

const heading1 = "Turf";
const heading2 = "Features";
const heading3 = "Benefits";
const desc1 =
  "Elevate your sports experience on our premier turf at Playzo33. Ideal for soccer, Cricket, and flag football enthusiasts, our impeccable surfaces provide the perfect arena for vibrant and thrilling games.";
const firstBullet1 =
  "Offering a versatile surface for various sports, well-lit facilities for day and night play, Ideal for soccer, Cricket, and flag football enthusiasts, and professional maintenance ensuring a safe environment.";

const secBullet1 =
  "Providing a multi-sport experience catering to soccer, Cricket, and flag football, with flexible playing hours for day or night activities and safety assurance through consistent maintenance.";

const buttonLabel = "Book Now";
const content = "This is turf";

export default function Turf() {
  const carouselItems = [
    <img style={{ width: "100%" }} src={turf1} alt={"title"} />,

    <img style={{ width: "100%" }} src={turf2} alt={"title"} />,
    <img style={{ width: "100%" }} src={turf3} alt={"title"} />,

    <img style={{ width: "100%" }} src={turf4} alt={"title"} />,
  ];

  return (
    <>
      <Service />
      <ServiceGrid
        radiusTopleft={"0px"}
        radiusTopright={"20px"}
        radiusbottompleft={"0px"}
        radiusbottompright={"20px"}
        direction={{ xs: "column", sm: "column", md: "column", lg: "row" }}
        icon={Turf_logo}
        carouselItems={carouselItems}
        heading1={heading1}
        desc1={desc1}
        heading2={heading2}
        firstBullet1={firstBullet1}
        heading3={heading3}
        secBullet1={secBullet1}
        buttonLabel={buttonLabel}
        content1={content}
        buttonName="Book now"
        linkName={routes.BOOKING_SERVICE}
      />
    </>
  );
}
