import {
  Badge,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
  keyframes,
} from "@mui/material";
import CustomDateCalendar, {
  Timings,
} from "../CommonComponents/CustomDateCalender/CustomDateCalender";
import { Link, useBlocker, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BookingParantmodal from "./BookingParantmodal";
import { BookingType } from "../CommonFiles/BookingType";
import BowlingMachine from "./bowlingMachine.webp";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CasinoIcon from "@mui/icons-material/Casino";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../CommonComponents/Colors";
import CricketNet from "./netCricket.jpg";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import DateUtils from "../Utils/DateUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import ModalComponent from "../CommonComponents/CustomDateCalender/ModalComponent";
import PersonIcon from "@mui/icons-material/Person";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SportsCricketIcon from "@mui/icons-material/SportsCricket";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import SportsVolleyballIcon from "@mui/icons-material/SportsVolleyball";
import assets from "../assets";
import backgroundimage from "./IMG_1517.jpg";
import badmintonImg from "./badminton-court.avif";
import boardGameImage from "../assets/BoardGames";
import boardImage from "./board.svg";
import cricketStumb from "./cricket_stumb.png";
import moment from "moment";
import playstationmage from "./playstation.svg";
import routes from "../routes/routes";
import styled from "styled-components";
import turfImage from "./play-ground-area-icon.svg";

const {
  "chess.jpeg": chessJpeg,
  "scrabble.png": scrabble,
  "Monopoly.png": Monopoly,
} = boardGameImage;

const {
  "Image (2).png": bowling,
  "Image (1).png": cricketnet,
  "playstation.png": playstation1,
  "playstation.png": playstation2,
  "playstation.png": playstation3,
  "turf.png": turf,
} = assets;

const TurfImages = [
  { image: turf, name: "Turf 2.O", value: 1 },
  { image: turf, name: "Turf 3.O", value: 2 },
  { image: turf, name: "Turf 2.O & 3.O", value: 3 },
];

const PlaystationImages = [
  { image: playstation1, name: "PS 1", value: 1 },
  { image: playstation2, name: "PS 2", value: 2 },
  { image: playstation3, name: "PS 1 & 2", value: 3 },
];

const BadmintonImages = [
  {
    image: badmintonImg,
    name: "Court 5.1",
    value: 1,
  },
  {
    image: badmintonImg,
    name: "Court 5.2",
    value: 2,
  },
  {
    image: badmintonImg,
    name: "Court 5.3",
    value: 3,
  },
  {
    image: badmintonImg,
    name: "Court 5.4",
    value: 4,
  },
];

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  
  100% {
    opacity: 1;
  }
`;

const BoardgameImages = [
  {
    image: chessJpeg,
    name: "Chess",
    value: 1,
  },
  {
    image: Monopoly,
    name: "Monopoly",
    value: 2,
  },
  {
    image: scrabble,
    name: "Scrabble",
    value: 3,
  },
];

interface TableDataItem {
  type: BookingType;
  name: string;
  date: string;
  time: string;
  court: number;
  amount: any;
  numberOfPersons: number;
}

const BookingParent: React.FC<{ type: BookingType }> = ({ type }) => {
  const [selectedService, setSelectedService] = useState("");
  const [selectedCourt, setSelectedCourt] = useState(1);
  const [tableData, setTableData] = useState<TableDataItem[]>(() => {
    const storedTableData = JSON.parse(
      localStorage.getItem("bookings") || "[]"
    );
    return storedTableData;
  });
  const isBlocked = useRef<any>(true);

  const excutedBlocker = [
    "/payment-booking",
    "/service-booking",
    routes.PLAYSTATION_BOOKING,
    routes.TURF_BOOKING,
    routes.BADMINTON_BOOKING,
    routes.BOWLINGMACHINE_BOOKING,
    routes.CRICKETNET_BOOKING,
    routes.BOARDGAME_BOOKING,
  ];
  // Block navigating elsewhere when data has been entered into the input
  let blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      tableData.length !== 0 &&
      currentLocation.pathname !== nextLocation.pathname &&
      !excutedBlocker.includes(nextLocation.pathname)
    ) {
      isBlocked.current = true;
      return true;
    }
    return false;
  });
  const nextLocation = localStorage.getItem("nextLocation");

  const images =
    type === BookingType.Turf
      ? TurfImages
      : type === BookingType.Playstaion
      ? PlaystationImages
      : type === BookingType.Badminton
      ? BadmintonImages
      : type === BookingType.BoardGame
      ? BoardgameImages
      : type === BookingType.BowlingMachine
      ? [{ image: bowling, name: "Bowling Machine", value: 1 }]
      : type === BookingType.CricketNet
      ? [{ image: cricketnet, name: "Cricket Net", value: 1 }]
      : [];

  const handleServiceSelection = (service: any) => {
    setSelectedService(service.name);
    setSelectedCourt(service.value);
    setTableData((prevTableData) => [...prevTableData]);
  };

  const handleRemoveItem = (indexToRemove: number) => {
    const updatedTableData = [...tableData];
    updatedTableData.splice(indexToRemove, 1);
    setTableData(updatedTableData);
    localStorage.setItem("bookings", JSON.stringify(updatedTableData));
    localStorage.removeItem("selectedService");
  };

  const handleAddMoreItems = () => {
    if (selectedService) {
      const selectedDate = "29 Feb 2024";
      const selectedTime = "9.00AM-11.00AM";
      const selectedAmount = "1500";

      // Check if the same item already exists in the tableData array
      const existingItem = tableData.find(
        (item) => item.type === type && item.name === selectedService
      );

      if (!existingItem) {
        // If the item doesn't exist, add it to the tableData array
        setTableData((prevTableData) =>
          prevTableData.concat({
            type,
            name: selectedService,
            date: selectedDate,
            time: selectedTime,
            court: selectedCourt,
            amount: selectedAmount,
            numberOfPersons: 0,
          })
        );
      }
    }

    setSelectedService("");
    setSelectedCourt(1);
    localStorage.removeItem("selectedService");
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(true);
  const [numberOfPersons, setNumberOfPersons] = useState("");
  const [validationError, setValidationError] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const convertToMinutes = (timeStr: {
    split: (arg0: string) => [any, any];
  }) => {
    const [time, period] = timeStr.split(" ");
    const [hours, minutes] = time.split(":").map(Number);
    const totalMinutes =
      (hours % 12) * 60 + minutes + (period === "PM" && hours < 12 ? 720 : 0);
    return totalMinutes;
  };

  const isContinuous = (slots: any[]) => {
    const slotsInMinutes = slots.map((slot) => {
      const [start, end] = slot.split(" ");
      return {
        start: convertToMinutes(start),
        end: convertToMinutes(end),
      };
    });

    // Sort by start time
    slotsInMinutes.sort((a, b) => a.start - b.start);

    // Check continuity
    for (let i = 0; i < slotsInMinutes.length - 1; i++) {
      if (slotsInMinutes[i].end !== slotsInMinutes[i + 1].start) {
        return false;
      }
    }

    return true;
  };

  function getTimeInMilliseconds(timeArray: string | any[], dateStr: any) {
    // Helper function to convert time in "H:MM AM/PM" format to minutes
    function timeToMilliseconds(
      time: { split: (arg0: string) => [any, any] },
      dateStr: any
    ) {
      const [timeStr, modifier] = time.split(" ");
      let [hours, minutes] = timeStr.split(":").map(Number);

      if (modifier === "PM" && hours !== 12) {
        hours += 12;
      } else if (modifier === "AM" && hours === 12) {
        hours = 0;
      }

      const date = new Date(`${dateStr} ${hours}:${minutes}`);
      return date.getTime();
    }

    const result = [];
    for (let i = 0; i < timeArray.length; i++) {
      const [start, end] = timeArray[i].split("-");

      const startMilliseconds = timeToMilliseconds(start, dateStr);
      const endMilliseconds = timeToMilliseconds(end, dateStr);

      result.push({
        start: startMilliseconds,
        end: endMilliseconds,
      });
    }

    // Return the first start time and last end time (joined time range)
    return {
      startMilliseconds: result[0].start,
      endMilliseconds: result[result.length - 1].end,
    };
  }

  function areIndexesContinuous(indexes: string | any[]) {
    if (indexes.length === 0) return false;

    for (let i = 1; i < indexes.length; i++) {
      if (indexes[i] !== indexes[i - 1] + 1) {
        return false;
      }
    }

    return true;
  }
  const handleProceedToPayment = async () => {
    if (allBookings.length === 0) {
      setIsModalOpen(true);
      return;
    }
    const bookingsWithTime = allBookings.reduce(
      (
        acc: {
          type: BookingType;
          name: string;
          startDate: string;
          startTime: number;
          endDate: string;
          endTime: number;
          court: number;
          amount: any;
          numberOfPersons: number;
        }[],
        booking
      ) => {
        const boo = booking.time as any;
        const splitamount = booking.amount / boo.length;
        // const indexes = boo.map((slot: string) =>
        //   Timings.findIndex((timing) => timing.name === slot)
        // );
        // const lastTime: { start: number; end: number }[] = [];
        // indexes.sort((a: number, b: number) => a - b);
        // const timeList = Timings;
        // const booValue: string[] = [];
        // indexes.map((index: any) => {
        //   booValue.push(timeList[index].name);
        // });

        // if (areIndexesContinuous(indexes)) {
        //   const timeRange = getTimeInMilliseconds(boo, booking.date);
        //   console.log("timeRange", timeRange);
        //   booValue.forEach(function (item: any) {
        //     const startDateTime = DateUtils.startTimeAddtoDate(item);
        //     const endDateTime = DateUtils.endTimeAddtoDate(item);
        //     const endMilli = DateUtils.joinDate(booking.date, endDateTime);
        //     const startMilli = DateUtils.joinDate(booking.date, startDateTime);
        //     const startMilliSec = moment(
        //       startMilli,
        //       "YYYY-MM-DD hh:mm:ss A"
        //     ).valueOf();
        //     const endMilliSec = moment(
        //       endMilli,
        //       "YYYY-MM-DD hh:mm:ss A"
        //     ).valueOf();
        //     const a = { start: startMilliSec, end: endMilliSec };
        //     lastTime.push(a);
        //   });
        //   const firstIndex = 0;
        //   const lastIndex = lastTime.length - 1;

        //   acc.push({
        //     type: booking.type,
        //     name: booking.name,
        //     startDate: DateUtils.formatDate(
        //       new Date(booking.date),
        //       "YYYY-MM-DD"
        //     ),
        //     startTime: lastTime[firstIndex].start as unknown as number,
        //     endDate: DateUtils.formatDate(new Date(booking.date), "YYYY-MM-DD"),
        //     endTime: lastTime[lastIndex].end as unknown as number,
        //     court: booking.court,
        //     amount: booking.amount,
        //     numberOfPersons: booking.numberOfPersons,
        //   });
        // }
        // else
        //  {

        boo.forEach(function (item: any) {
          const startDateTime = DateUtils.startTimeAddtoDate(item);
          const endDateTime = DateUtils.endTimeAddtoDate(item);
          const endMilli = DateUtils.joinDate(booking.date, endDateTime);
          const startMilli = DateUtils.joinDate(booking.date, startDateTime);
          const startMilliSec = moment(
            startMilli,
            "YYYY-MM-DD hh:mm:ss A"
          ).valueOf();
          const endMilliSec = moment(
            endMilli,
            "YYYY-MM-DD hh:mm:ss A"
          ).valueOf();

          acc.push({
            type: booking.type,
            name: booking.name,
            startDate: DateUtils.formatDate(
              new Date(booking.date),
              "YYYY-MM-DD"
            ),
            startTime: startMilliSec,
            endDate: DateUtils.formatDate(new Date(booking.date), "YYYY-MM-DD"),
            endTime: endMilliSec,
            court: booking.court,
            amount: splitamount,
            numberOfPersons: booking.numberOfPersons,
          });
        });
        // }
        return acc;
      },
      []
    );

    setLoading(true);

    // Simulate a network request or some async operation
    setTimeout(() => {
      setLoading(false);
      navigate("/payment-booking", {
        state: { selectedService, bookingsWithTime },
      });
    }, 1000); // Adjust the timeout as needed
  };
  const [loading, setLoading] = useState(false);

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 1 && inputValue <= 10) {
      setNumberOfPersons(inputValue.toString());
      setValidationError("");
    } else {
      setNumberOfPersons("");
      setValidationError("Please enter a valid number of persons (1 to 10).");
    }
  };

  useEffect(() => {
    localStorage.setItem("bookings", JSON.stringify(tableData));
  }, [tableData]);

  useEffect(() => {
    const selectedServiceFromState = location.state?.selectedService;
    if (selectedServiceFromState) {
      setSelectedService(selectedServiceFromState);
    }
  }, [location.state?.selectedService]);

  const allBookings = tableData.filter(
    (item) =>
      item.type === BookingType.Turf ||
      item.type === BookingType.Playstaion ||
      item.type === BookingType.Badminton ||
      item.type === BookingType.BoardGame ||
      item.type === BookingType.BowlingMachine ||
      item.type === BookingType.CricketNet
  );

  const cleanupLocalStorage = () => {
    localStorage.removeItem("bookings");
    localStorage.removeItem("selectedService");
  };

  const handlegoBack = () => {
    // if (selectedService) {
    //   setSelectedService("");
    //   setSelectedCourt(1);
    // } else {
    //   navigate(-1);
    // }
    navigate("/service-booking");

    localStorage.removeItem("selectedService");
  };

  const handleServiceSelectBack = () => {
    setSelectedService("");
  };

  useEffect(() => {
    // Read selected service from local storage
    const storedSelectedService = localStorage.getItem("selectedService");

    if (storedSelectedService) {
      setSelectedService(storedSelectedService);
    }
  }, []);

  const [selectedModal, setSelectedModal] = useState(false);
  const isLocalCleared =
    localStorage.getItem("bookings") == null ||
    localStorage.getItem("bookings") === undefined ||
    localStorage.getItem("bookings") === "[]";

  useEffect(() => {
    if (blocker.state === "blocked" && !isLocalCleared) {
      setSelectedModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocker, blocker.state]);

  const handleModalConfirm = () => {
    localStorage.removeItem("nextLocation");
    if (blocker.location) {
      localStorage.setItem("nextLocation", blocker.location.pathname);
    }
    isBlocked.current = false;
    setTableData([]);
    cleanupLocalStorage();
    if (blocker.state === "blocked") {
      blocker.reset();
    }
    setSelectedModal(false); // Close the modal after confirmation
  };

  const handleModalCancel = () => {
    setSelectedModal(false); // Close the modal
  };

  useEffect(() => {
    if (nextLocation && !isBlocked.current) {
      localStorage.removeItem("nextLocation");
      navigate(nextLocation);
    }
  }, [nextLocation, navigate]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  interface GradientIconButtonProps {
    isActive: boolean;
  }

  const GradientIconButton = styled("div")<GradientIconButtonProps>(
    ({ isActive }) => ({
      background: isActive
        ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
        : "gray", // Gray background for non-selected
      color: "black",
      borderRadius: "50%",
      padding: "10px",
      // margin: "10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "2rem",
      textTransform: "capitalize",
      width: "35px",
      height: "35px",

      "@media (min-width: 768px)": {
        // md and up
        transition: "transform 0.3s ease-in-out", // Smooth transition only on md and larger
        "&:hover": {
          transform: "scale(1.2)", // Zoom-in effect
        },
      },
    })
  );

  const routesforNavigation = [
    {
      name: BookingType.Turf,
      icon: <SportsCricketIcon />,
      linkDetails: routes.TURF_BOOKING,
    },
    {
      name: BookingType.Playstaion,
      icon: <SportsEsportsIcon />,
      linkDetails: routes.PLAYSTATION_BOOKING,
    },
    {
      name: BookingType.Badminton,
      icon: <SportsTennisIcon />,
      linkDetails: routes.BADMINTON_BOOKING,
    },
    {
      name: BookingType.CricketNet,
      icon: cricketStumb,
      linkDetails: routes.CRICKETNET_BOOKING,
    },
    {
      name: BookingType.BowlingMachine,
      icon: <SportsVolleyballIcon />,
      linkDetails: routes.BOWLINGMACHINE_BOOKING,
    },
    {
      name: BookingType.BoardGame,
      icon: <CasinoIcon />,
      linkDetails: routes.BOARDGAME_BOOKING,
    },
  ];
  const totalAmount = allBookings.reduce(
    (acc, booking) => acc + booking.amount,
    0
  );

  const getDayName = (dateString: any) => {
    const date = new Date(dateString);
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return daysOfWeek[date.getDay()];
  };

  const hasItems = tableData.length > 0;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        component={"image"}
        sx={{
          position: "relative",
          backgroundImage: `url(${backgroundimage})`,
          backgroundSize: "cover", // Fit the background image to cover the entire container
          backgroundPosition: "center",
          backgroundRepeat: "repeat",
          objectFit: "cover", // Set object-fit to cover
          height: "100%",
          // Ensure the height is 100%
        }}
      >
        <Box
          sx={{
            position: "relative",
            zIndex: 1, // Ensure content appears above background
            marginTop: { xs: 0, sm: 0, md: 0, lg: "2rem" },
            padding: "0 20px",
          }}
        >
          <Box
            position={"absolute"}
            sx={{
              top: { xs: 0, sm: 0, md: "10px" },
              left: { xs: 0, sm: 0, md: "10%" },
            }}
            width={"40%"}
            textAlign={{ xs: "start", sm: "start", md: "center" }}
          >
            {isBackButtonVisible && (
              <Box
                onClick={handlegoBack}
                display={"flex"}
                alignItems={"center"}
                marginTop={"10px"}
              >
                <IconButton>
                  <ArrowBackIosNewIcon
                    style={{
                      fontSize: "20px",
                      fontFamily: "Montserrat', sans-serif",
                      color: Colors.BUTTON,
                    }}
                  />
                </IconButton>
                <Typography
                  fontWeight={"600"}
                  fontSize={"18px"}
                  fontFamily={"'Montserrat', sans-serif"}
                  color={Colors.BUTTON}
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  Back
                </Typography>
              </Box>
            )}
          </Box>{" "}
          <Box>
            <Typography
              textAlign={"center"}
              fontSize={"32px"}
              fontWeight={700}
              sx={{ color: "Black" }}
              fontFamily={"Montserrat, sans-serif"}
            >
              Booking
            </Typography>
            <Divider
              orientation="horizontal"
              sx={{
                width: { xs: "25%", sm: "25%", md: "8%" },
                backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                height: "2px", // Adjust the height to make it more visible if needed
                margin: "auto",
              }}
            />
          </Box>
          <Box
            maxWidth={800}
            margin={"0 auto"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={{
              xs: "space-evenly",
              sm: "space-evenly",
              md: "space-between",
            }}
            flexWrap={"wrap"}
            gap={"5.5px"}
          >
            {routesforNavigation.map((item, index) => (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"5px"}
                alignItems={"center"}
                key={index}
              >
                <Link
                  style={{
                    textAlign: "center",
                  }}
                  key={index}
                  to={item.linkDetails}
                >
                  <GradientIconButton isActive={type === item.name}>
                    {item.name === BookingType.CricketNet ? (
                      <img
                        src={cricketStumb} // Ensure cricketStumb is correctly typed as a string
                        alt="Turf"
                        style={{ width: "30px" }}
                      />
                    ) : (
                      item.icon
                    )}
                  </GradientIconButton>
                </Link>
                <Box>
                  <Typography
                    textAlign={"center"}
                    fontFamily={"Montserrat, sans-serif"}
                    fontSize={"12px"}
                    textTransform={"capitalize"}
                    color={"black"}
                    fontWeight={700}
                  >
                    {item.name === ("turf" as BookingType)
                      ? "Turf / Football"
                      : item.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
            maxWidth={800}
            width={"100%"}
            margin={"10px auto"}
            paddingBottom={1}
          >
            <Box
              margin={{ xs: "5px 0", sm: "5px o", md: "20px 0" }}
              display={"flex"}
              alignItems={"center"}
              gap={"5px"}
            >
              <IconButton onClick={handleServiceSelectBack}>
                <ArrowBackIosNewIcon
                  style={{
                    fontSize: "20px",
                    fontFamily: "Montserrat', sans-serif",
                    color: Colors.BUTTON,
                  }}
                />
              </IconButton>{" "}
              <Box display={"flex"} gap={"10px"} flexWrap={"wrap"}>
                <Typography
                  fontWeight={700}
                  fontSize={{ xs: "16px", md: "18px" }}
                  fontFamily={"Montserrat, sans-serif"}
                >
                  Seleted Service :
                </Typography>
                <Typography
                  sx={{
                    textTransform: "none",
                  }}
                  fontSize={{ xs: "16px", md: "18px" }}
                  color={Colors.BLACK}
                  fontFamily={"Montserrat, sans-serif"}
                  fontWeight={900}
                >
                  {type === BookingType.Turf
                    ? "Turf / Football"
                    : type === BookingType.Playstaion
                    ? "Playstation"
                    : type === BookingType.Badminton
                    ? "Badminton"
                    : type === BookingType.BoardGame
                    ? "Board Games"
                    : type === BookingType.BowlingMachine
                    ? "Bowling Machine"
                    : type === BookingType.CricketNet
                    ? "Cricket Net"
                    : "Unknown Type"}{" "}
                </Typography>
              </Box>
            </Box>

            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                background: "rgba(255, 255, 255, 0.2)", // Semi-transparent white background
                backdropFilter: "blur(2px)", // Blurring the background behind the card
                boxShadow: "inset 20px -2px 20px 20px rgba(0, 0, 0, 0.1)", // Subtle shadow
                border: "1px solid rgba(255, 255, 255, 0.3)", // Soft border
                borderRadius: "15px", // Rounded corners
                padding: "20px",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                gap={"10px"}
              >
                <Box
                  fontWeight={700}
                  fontSize={"18px"}
                  fontFamily={"Montserrat, sans-serif"}
                  display={"flex"}
                  alignItems={"center"}
                  gap={"10px"}
                >
                  {" "}
                  <Typography
                    fontWeight={900}
                    fontSize={"18px"}
                    fontFamily={"Montserrat, sans-serif"}
                  >
                    {type === BookingType.Turf
                      ? "Turf / Football"
                      : type === BookingType.Playstaion
                      ? "Playstation"
                      : type === BookingType.Badminton
                      ? "Badminton"
                      : type === BookingType.BoardGame
                      ? "Board Games"
                      : type === BookingType.BowlingMachine
                      ? "Bowling Machine"
                      : type === BookingType.CricketNet
                      ? "Cricket Net"
                      : "Unknown Type"}{" "}
                    -
                  </Typography>
                  Type
                </Box>
                {selectedService ? (
                  <>
                    <Typography
                      sx={{
                        background:
                          "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                        textTransform: "none",
                        fontFamily: "Montserrat, sans-serif",
                      }}
                      padding={"10px"}
                      fontSize={"14px"}
                      color={"Black"}
                      fontFamily={"Montserrat, sans-serif"}
                      fontWeight={700}
                      width={"fit-content"}
                      borderRadius={"10px"}
                    >
                      {selectedService}
                    </Typography>
                    {selectedService && type === BookingType.Badminton ? (
                      <Box mt={2}>
                        <TextField
                          placeholder="Number of persons"
                          required
                          fullWidth
                          sx={{
                            "& .MuiInputBase-input": {
                              fontFamily: "Montserrat, sans-serif !important",
                              fontWeight: 600,
                            },
                          }}
                          value={numberOfPersons}
                          onChange={handleNumber}
                          inputProps={{ max: 10 }}
                          error={!!validationError}
                          helperText={validationError}
                        />
                        <Typography
                          mt={2}
                          fontFamily={"Montserrat, sans-serif"}
                          fontSize={"18px"}
                          fontWeight={700}
                        >
                          <span
                            style={{
                              fontWeight: 900,
                              fontFamily: "Montserrat, sans-serif",
                            }}
                          >
                            Note:
                          </span>{" "}
                          No.of members going to play
                        </Typography>
                      </Box>
                    ) : undefined}
                  </>
                ) : (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    gap={"10px"}
                    flexWrap={"wrap"}
                  >
                    {images.map((item, index) => (
                      <Box
                        key={index}
                        display={selectedService ? "none" : "block"}
                        sx={{
                          ":hover": {
                            background: Colors.BUTTON_COLOR,
                            borderRadius: "15px",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => handleServiceSelection(item)}
                        className={
                          selectedService === item.name ? "selected" : ""
                        }
                      >
                        <Typography
                          sx={{
                            background:
                              "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                            textTransform: "none",
                            fontFamily: "Montserrat, sans-serif",
                            width: "fit-content",
                          }}
                          borderRadius={"10px"}
                          padding={"10px"}
                          fontSize={"14px"}
                          color={Colors.BLACK}
                          fontFamily={"Montserrat, sans-serif"}
                          fontWeight={700}
                          width={"fit-content"}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Divider
                orientation="vertical"
                sx={{
                  height: 100,
                  display: { xs: "none", sm: "none", md: "block" },
                }}
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                pt={{ xs: "10px", md: "0px" }}
                flexDirection={"column"}
              >
                {type === BookingType.Turf ? (
                  <img src={turfImage} width={80} alt="44" />
                ) : type === BookingType.Playstaion ? (
                  <img src={playstationmage} width={80} alt="44" />
                ) : type === BookingType.BoardGame ? (
                  <img src={boardImage} width={80} alt="44" />
                ) : type === BookingType.Badminton ? (
                  <SportsTennisIcon
                    sx={{
                      fontSize: "60px",
                    }}
                  />
                ) : type === BookingType.CricketNet ? (
                  <img src={CricketNet} width={80} alt="44" />
                ) : type === BookingType.BowlingMachine ? (
                  <img src={BowlingMachine} width={80} alt="44" />
                ) : (
                  "other"
                )}
                <Typography
                  fontWeight={700}
                  fontFamily={"Montserrat, sans-serif"}
                  color={"black"}
                >
                  {type === BookingType.Turf
                    ? selectedService
                    : type === BookingType.Playstaion
                    ? selectedService
                    : type === BookingType.Badminton
                    ? selectedService
                    : type === BookingType.BoardGame
                    ? selectedService
                    : type === BookingType.BowlingMachine
                    ? selectedService
                    : type === BookingType.CricketNet
                    ? selectedService
                    : "Unknown Type"}
                </Typography>
              </Box>
            </Card>

            {/* {(selectedService || tableData.length > 0) && ( */}
            <Box
              paddingTop={"20px"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-evenly"}
              // maxWidth={600}
              margin={"0 auto"}
            >
              <CustomDateCalendar
                tableData={tableData}
                setTableData={setTableData}
                selectedService={selectedService}
                type={type}
                setIsBackButtonVisible={setIsBackButtonVisible}
                setValidationError={setValidationError}
                numberOfPersons={numberOfPersons}
                setNumberOfPersons={setNumberOfPersons}
                handleAddmore={handleAddMoreItems}
                handleRemoveItem={handleRemoveItem}
              />
            </Box>
            {/* // )} */}
          </Box>
          {!selectedService &&
            type !== BookingType.BowlingMachine &&
            type !== BookingType.CricketNet && (
              <Typography
                textAlign={"center"}
                fontFamily={"Montserrat, sans-serif"}
                fontWeight={900}
                paddingBottom={2}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={"5px"}
                color="red"
              >
                <ReportGmailerrorredRoundedIcon
                  sx={{
                    color: "red",
                  }}
                />{" "}
                Please Select a type to view time slots.
              </Typography>
            )}
          <Box
            margin={"0 auto"}
            maxWidth={550}
            display={tableData.length > 0 ? "flex" : "none"}
            justifyContent={"space-between"}
            flexWrap={{ xs: "wrap", md: "nowrap" }}
            paddingBottom={"2rem"}
            gap={"20px"}
            paddingTop={{ xs: 4, sm: 2, md: 2, lg: "3rem" }}
          >
            <Button
              sx={{
                border: "1px solid #21CBF3",
                background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Initial background color
                textTransform: "none",
                fontFamily: "Montserrat, sans-serif",
                width: "fit-content",
                color: "black", // Change text color to contrast with white background
                display: tableData.length > 0 ? "flex" : "none",
                fontWeight: 700,
                position: "relative", // Required for positioning pseudo-element
                overflow: "hidden", // Ensure the pseudo-element doesn't overflow
                alignItems: "center",
                gap: "10px",
                transition: "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background color and icon movement
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  borderRadius: "inherit", // Match the button's border-radius
                  border: "2px solid transparent", // Transparent border to show gradient border
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient border color
                  zIndex: -1, // Ensure the border is behind the button
                  transition: "background 0.3s ease", // Smooth transition for gradient border
                },
                "&:hover": {
                  background:
                    "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient background on hover
                  color: "white", // Change text color to white on hover
                  "&::before": {
                    background:
                      "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient border on hover
                  },
                  "& svg": {
                    color: "white", // Change icon color to white on hover
                  },
                },
              }}
            >
              <CurrencyRupeeIcon
                sx={{
                  color: Colors.BACK_BLACK,
                  fontSize: "18px",
                  // "&:hover": {
                  //   color: "white",
                  // },
                }}
              />
              {totalAmount}.00
            </Button>

            <Box display={"flex"} gap={"10px"}>
              <Button
                sx={{
                  border: "1px solid #21CBF3",
                  background: loading
                    ? "#2196F3"
                    : "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                  textTransform: "none",
                  fontFamily: "Montserrat, sans-serif",
                  width: "fit-content",
                  color: "black", // Change text color to contrast with white background
                  display: tableData.length > 0 ? "flex" : "none",
                  fontWeight: 700,
                  position: "relative", // Required for positioning pseudo-element
                  overflow: "hidden", // Ensure the pseudo-element doesn't overflow
                  alignItems: "center",
                  gap: "10px",
                  transition: "background-color 0.3s ease, transform 0.3s ease", // Smooth transition for background color and icon movement
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: "0",
                    left: "0",
                    right: "0",
                    bottom: "0",
                    borderRadius: "inherit", // Match the button's border-radius
                    border: "2px solid transparent", // Transparent border to show gradient border
                    background:
                      "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient border color
                    zIndex: -1, // Ensure the border is behind the button
                    transition: "background 0.3s ease", // Smooth transition for gradient border
                  },
                  "&:hover": {
                    background:
                      "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient background on hover
                    color: "white", // Change text color to white on hover
                    "&::before": {
                      background:
                        "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Gradient border on hover
                    },
                    "& .MuiSvgIcon-root": {
                      transform: "translateX(40px)", // Move icon to the right on hover
                    },
                  },
                }}
                onClick={handleProceedToPayment}
                disabled={loading} // Disable button while loading
                startIcon={
                  loading ? (
                    <CircularProgress style={{ color: "white" }} size={24} />
                  ) : null
                }
              >
                {loading ? "" : "Proceed to Checkout"}
                {!loading && (
                  <ArrowForwardIcon
                    sx={{
                      transition: "transform 0.3s ease",
                    }}
                  />
                )}
              </Button>
              {type !== BookingType.BowlingMachine &&
                type !== BookingType.CricketNet && (
                  <IconButton
                    onClick={handleOpen}
                    sx={{
                      display: selectedService ? "none" : "block",

                      border: `2px solid ${Colors.BUTTON_COLOR}`,
                      color: Colors.BLACK,
                      borderRadius: "50%",
                      padding: "8px",
                      transition: "background-color 0.3s ease",
                      animation: hasItems ? `${fadeInOut} 3s ease-out` : "none", // Apply animation conditionally
                      height: 45,
                      width: 45,
                      backgroundColor: Colors.BUTTON_COLOR,

                      "&:hover": {
                        backgroundColor: Colors.BUTTON_COLOR,
                        color: "black",
                      },
                    }}
                  >
                    <Badge badgeContent={tableData.length}>
                      <ShoppingCartIcon />
                    </Badge>
                  </IconButton>
                )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap={"10px"} justifyContent="start">
          <Modal open={open}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: 450,
                bgcolor: "background.paper",
                borderRadius: "12px",
                boxShadow: 24,
                width: { xs: "80%", sm: "80%", md: "80%", lg: "100%" },
                p: "20px",
              }}
            >
              <Box display={"flex"} justifyContent={"space-between"}>
                <Box>
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    variant="h6"
                    component="h2"
                  >
                    Your Bookings
                  </Typography>
                  <Divider
                    orientation="horizontal"
                    sx={{
                      width: "40%",
                      backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                      height: "2px", // Adjust the height to make it more visible if needed
                    }}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <IconButton onClick={handleClose}>
                    <CloseIcon
                      style={{
                        color: "black",
                      }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  maxHeight: 450,
                  overflowY: "auto",
                  overflowX: "hidden",
                  scrollbarWidth: "thin",
                  paddingRight: "20px",
                }}
              >
                {tableData.length > 0 ? (
                  tableData.map((booking: any, index: any) => (
                    <>
                      <Typography
                        fontWeight={700}
                        fontFamily={"Montserrat, sans-serif"}
                        fontSize={"18px"}
                        textTransform={"capitalize"}
                        textAlign={"end"}
                      >
                        Bookings {index + 1}
                      </Typography>
                      <Box
                        key={index}
                        fontFamily={"Montserrat, sans-serif"}
                        sx={{ mb: 2 }}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        borderBottom={"1px solid black"}
                        padding={"10px 0"}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          gap={"5px"}
                        >
                          <Typography
                            fontWeight={500}
                            fontFamily={"Montserrat, sans-serif"}
                            fontSize={"18px"}
                            textTransform={"capitalize"}
                          >
                            {booking.type === BookingType.Turf ? (
                              <SportsSoccerIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />
                            ) : booking.type === BookingType.Playstaion ? (
                              <SportsEsportsIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />
                            ) : booking.type === BookingType.Badminton ? (
                              <SportsTennisIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />
                            ) : booking.type === BookingType.CricketNet ? (
                              <SportsCricketIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />
                            ) : booking.type === BookingType.BowlingMachine ? (
                              <SportsVolleyballIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />
                            ) : booking.type === BookingType.BoardGame ? (
                              <CasinoIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />
                            ) : null}
                            {/* {booking.type} */}
                            {booking.type === ("turf" as BookingType)
                              ? "Turf / Football"
                              : booking.type}
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontFamily={"Montserrat, sans-serif"}
                            fontSize={"18px"}
                            textTransform={"capitalize"}
                          >
                            <DescriptionIcon
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: Colors.BUTTON_COLOR,
                              }}
                            />{" "}
                            {booking.name}
                          </Typography>
                          <Typography
                            fontWeight={700}
                            fontFamily={"Montserrat, sans-serif"}
                            fontSize={"18px"}
                            fontStyle={"italic"}
                          >
                            <CalendarTodayIcon
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: Colors.BUTTON_COLOR,
                              }}
                            />{" "}
                            {new Date(booking.date).toLocaleDateString(
                              "en-US",
                              {
                                month: "short", // 'Sep'
                                day: "numeric", // '10'
                              }
                            )}{" "}
                            ({getDayName(booking.date)})
                          </Typography>
                          <Typography
                            fontWeight={700}
                            fontFamily={"Montserrat, sans-serif"}
                            fontSize={"18px"}
                            fontStyle={"italic"}
                          >
                            <AccessTimeIcon
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: Colors.BUTTON_COLOR,
                              }}
                            />{" "}
                            {booking.time.join(", ")}
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontFamily={"Montserrat, sans-serif"}
                            fontSize={"18px"}
                          >
                            <CurrencyRupeeIcon
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: Colors.BUTTON_COLOR,
                              }}
                            />{" "}
                            {booking.amount}
                          </Typography>
                          <Typography
                            fontWeight={500}
                            fontFamily={"Montserrat, sans-serif"}
                            fontSize={"18px"}
                          >
                            <AccessTimeIcon
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: Colors.BUTTON_COLOR,
                              }}
                            />{" "}
                            {booking.duration} hour
                          </Typography>

                          {booking.numberOfPersons ? (
                            <Typography
                              fontWeight={500}
                              fontFamily={"Montserrat, sans-serif"}
                              fontSize={"18px"}
                            >
                              <PersonIcon
                                sx={{
                                  verticalAlign: "middle",
                                  mr: 1,
                                  color: Colors.BUTTON_COLOR,
                                }}
                              />{" "}
                              Number of Persons: {booking.numberOfPersons}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>

                        <IconButton onClick={() => handleRemoveItem(index)}>
                          <DeleteIcon
                            style={{
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Box>
                    </>
                  ))
                ) : (
                  <Typography
                    fontWeight={700}
                    fontFamily={"Montserrat, sans-serif"}
                    fontSize={"18px"}
                    variant="body2"
                  >
                    No bookings available.
                  </Typography>
                )}
              </Box>
              <Box
                paddingTop={"20px "}
                display={"flex"}
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                gap={"5px !important"}
              >
                <Typography
                  fontWeight={700}
                  fontFamily={"Montserrat, sans-serif"}
                  fontSize={{ xs: "15px", md: "18px" }}
                >
                  Total Items: {tableData.length}
                </Typography>
                <Typography
                  fontWeight={700}
                  fontFamily={"Montserrat, sans-serif"}
                  fontSize={{ xs: "15px", md: "18px" }}
                >
                  Total amount : {totalAmount}
                </Typography>
              </Box>
            </Box>
          </Modal>
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(255, 255, 255, 90%)", // Adjust the alpha value (0.5 for 50% opacity)
          }}
        />
      </Box>

      <ModalComponent
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        text={"Please make at least one booking before proceeding to payment"}
        headingText={""}
      />

      <BookingParantmodal
        open={selectedModal}
        handleClose={handleModalCancel}
        text={
          "Are you sure you want to leave? Your selected service will be lost."
        }
        handleConfirmReset={handleModalConfirm}
      />
    </>
  );
};

export default BookingParent;
