import { Box } from "@mui/material";

export default function Service() {
  return (
    <>
      <Box marginBottom="60px">{/* <ServiceHeaders /> */}</Box>

      {/* <MuiTab /> */}
    </>
  );
}
