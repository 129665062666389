import axios, { AxiosRequestConfig } from "axios";


export default class UploadApi {
  public static async uploadFile(
    presignedRequest: any,
    file: File | Blob,
    handleProgress?: (progress: number) => void
  ) {
    const formData = new FormData();
    Object.keys(presignedRequest.body).forEach((key) => {
      formData.append(key, presignedRequest.body[key]);
    });
    formData.append("Content-Type", file.type);
    formData.append("file", file);
    const config: AxiosRequestConfig<FormData> = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = progressEvent.total
          ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
          : 0;
        handleProgress?.(percentCompleted);
      },
    };
    await axios.post(presignedRequest.uploadUrl, formData, config);
    return presignedRequest.publicUrl;
  }
}
