import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  styled,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import AppDrawer from "./AppDrawer";
import BookingParantmodal from "../BookingService/BookingParantmodal";
import Colors from "../CommonComponents/Colors";
import IconButton from "@mui/material/IconButton";
import LoginForm from "../pages/login/LoginForm";
import { Logout } from "@mui/icons-material";
import MenuTwoToneIcon from "@mui/icons-material/MenuTwoTone";
import PersonIcon from "@mui/icons-material/Person";
import UserLoginApi from "../api/UserLoginApi";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import logoImage from "../assets/logo";
import routes from "../routes/routes";

const { "Playzo.svg": logo } = logoImage;

interface MobileHeaderProps {
  setActiveTab: (event: any) => void;
  activeTab: string;
}

const HeaderLink = styled(Link)`
  text-decoration: none;
  position: relative;
  color: graytext;

  &:hover {
    color: black;
  }
`;
export default function MoblieHeader({
  activeTab,
  setActiveTab,
}: MobileHeaderProps) {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = useCallback(() => setOpen(true), []);
  const handleDrawerClose = useCallback(() => setOpen(false), []);

  const [isHovered, setIsHovered] = useState(false);
  const handleButtonClick = () => {
    const phoneNumber = "7094460944";
    const whatsappLink = `https://wa.me/${phoneNumber}`;

    // Open WhatsApp link in a new window
    window.open(whatsappLink, "_blank");
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmLogout = async () => {
    try {
      await UserLoginApi.logoutUser();

      localStorage.clear();
      setIsLoggedIn(false);

      window.location.href = routes.ROOT;
    } catch {}
  };

  const handleLogout = async (event: any) => {
    event.preventDefault();

    // Check if local storage is empty
    const isLocalStorageEmpty =
      localStorage.getItem("bookings") == null ||
      localStorage.getItem("bookings") === undefined ||
      localStorage.getItem("bookings") === "[]";

    if (!isLocalStorageEmpty) {
      setOpenModal(true); // Open the modal instead of using window.confirm
      return;
    }

    try {
      // Logout user
      await UserLoginApi.logoutUser();

      localStorage.clear();
      setIsLoggedIn(false);

      window.location.href = routes.ROOT;
    } catch {
      console.log("Logout failed");
    }
  };

  interface User {
    name?: string;
  }

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const userData = localStorage.getItem("user");
    const parsedUserData = userData && JSON.parse(userData);

    if (parsedUserData) {
      setUser(parsedUserData);
      setIsLoggedIn(true); // Set to true if there is user data
    } else {
      setUser(null);
      setIsLoggedIn(false); // Set to false if there is no user data
    }
  }, []);

  const [Modalopen, setModalOpen] = useState(false);
  // const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const openvalue = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const navigate = useNavigate();

  const handleChange = () => {
    navigate(routes.USERPROFILE);
    setAnchorEl(null);
  };

  const [shrinkHeader, setShrinkHeader] = useState(false); // State for header shrink

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      setShrinkHeader(scrollTop > 50); // Adjust the threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <Box
        display={{ xs: "flex", sm: "flex", md: "none" }}
        alignItems="center"
        sx={{
          backgroundColor: Colors.BLACK,
          transition:
            "height 0.4s ease-in-out, background-color 0.4s ease-in-out",
          height: shrinkHeader ? "80px" : "100px", // Header height adjusts smoothly
          alignItems: "center",
          justifyContent: "space-between",
          // position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000, // Ensure header stays on top
          boxShadow: shrinkHeader ? "0px 4px 8px rgba(0, 0, 0, 0.1)" : "none", // Optional shadow on scroll
        }}
      >
        <Stack
          padding={"10px"}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <HeaderLink to={"/"}>
            <img
              width={"100%"}
              style={{
                height: shrinkHeader ? "50px" : "60px", // Adjust logo size
                transition: "all 0.3s ease",
                maxWidth: 150,
              }}
              src={logo}
              alt="playzo"
            />
          </HeaderLink>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton
              style={{
                maxWidth: "60px",
                position: "fixed",
                bottom: "12%",
                left: "5%",
                background: isHovered ? Colors.WHITE : "#075E54", // Change color on hover
                color: isHovered ? "#075E54" : "white",
                borderRadius: "50%",
                height: "60px",
                width: "100%",
                border: `2px solid #075E54`,
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={handleButtonClick}
            >
              {isHovered ? (
                <WhatsAppIcon
                  style={{
                    color: Colors.BUTTON_COLOR,
                  }}
                />
              ) : (
                <WhatsAppIcon
                  style={{
                    color: Colors.WHITE,
                  }}
                />
              )}
            </IconButton>
            {isLoggedIn ? (
              <Box display={isLoggedIn ? "block" : "none"}>
                <Box>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={openvalue ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openvalue ? "true" : undefined}
                  >
                    <PersonIcon
                      sx={{ width: 40, height: 40, color: Colors.WHITE }}
                    />
                  </IconButton>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={openvalue}
                    onClose={handleMenuClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        width: 200, // Adjust the width as needed
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={handleChange}
                      sx={{
                        "&:hover": {
                          backgroundColor:
                            "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Change background color on hover
                          color: Colors.WHITE,
                        },
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <Avatar
                        style={{
                          color: Colors.BUTTON_COLOR,
                          background: "white",
                        }}
                      />{" "}
                      My Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={handleLogout}
                      sx={{
                        color: "#f44336",
                        padding: "10px 20px",
                        "&:hover": {
                          backgroundColor:
                            "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)", // Change background color on hover
                          color: Colors.WHITE,
                        },
                        fontFamily: "Montserrat, sans-serif",
                      }}
                    >
                      <ListItemIcon>
                        <Logout
                          style={{
                            color: Colors.BUTTON_COLOR,
                            background: "white",
                          }}
                          fontSize="small"
                        />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
            ) : (
              ""
            )}

            <IconButton size="large" onClick={handleDrawerOpen}>
              <MenuTwoToneIcon
                style={{ fontSize: 40, color: Colors.BUTTON_COLOR }}
              />
            </IconButton>
          </Box>
        </Stack>
      </Box>

      <AppDrawer
        handleLogout={handleLogout}
        user={user}
        open={open}
        onClose={handleDrawerClose}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        isLoggedIn={isLoggedIn}
        setModalOpen={setModalOpen}
      />

      <BookingParantmodal
        open={openModal}
        handleClose={handleCloseModal}
        handleConfirmReset={handleConfirmLogout}
        text="Are you sure to log out?"
      />

      <LoginForm handleClose={handleClose} open={Modalopen} />
    </>
  );
}
