import { Box, Pagination, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import BookingApi from "../../api/BookingApi";
import assets from "../../assets";
import backgroundimage from "../../BookingService/IMG_1517.jpg";
import AppContainer from "../../CommonComponents/AppContainer";
import Colors from "../../CommonComponents/Colors";
import ball from "../../CommonFiles/Sports.svg";
import ListCard from "./ListCard";

const { "Image (6).png": Image6 } = assets;

export default function UserProfile() {
  const [user, setUser] = useState(null);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [userSports, setUserSports] = useState<string[]>([]);

  const CapitalizeFirstLetter = (str: any) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  useEffect(() => {
    const user = localStorage.getItem("user");
    const userData = user && JSON.parse(user);
    setUser(userData);
    setUserSports(userData["interestedSports"]);
  }, []);

  const fetchInfo = useCallback(async () => {
    if (user && user["id"]) {
      try {
        await BookingApi.filterBook({
          user: user["id"],
        }).then((data) => {
          setCount(Math.round(data.length / 4));
        });

        await BookingApi.filterPage({
          user: user["id"],
          page: page,
          limit: 4,
        }).then((data) => {
          setFilteredData(data);
        });
      } catch {
        console.log("Error fetching data");
      }
    }
  }, [page, user]);

  useEffect(() => {
    fetchInfo();
  }, [fetchInfo]);

  return (
    <Box
      component={"image"}
      sx={{
        position: "relative",
        backgroundImage: `url(${backgroundimage})`,
        backgroundSize: "cover", // Fit the background image to cover the entire container
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
        objectFit: "cover", // Set object-fit to cover
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.96)",
        }}
      />
      <Box
        sx={{
          position: "relative",
          zIndex: 1, // Ensure content appears above background
          color: "black", // Set text color for content
        }}
      >
        <AppContainer m={"2rem auto"} p={0}>
          <Stack
            justifyContent={"space-evenly"}
            direction={{ xs: "column", sm: "column", md: "row" }}
            px={{ xs: 0, sm: 5, md: 0 }}
            padding={{ xs: "0 30px", sm: "0 30px" }}
          >
            <Box>
              <Typography
                variant="h6"
                color={Colors.BUTTON_COLOR}
                fontWeight={800}
                mb={3}
                fontFamily={"Montserrat, sans-serif"}
              >
                Basic Information
              </Typography>
              <Stack direction="column" spacing={2}>
                <Stack
                  flexWrap={"wrap"}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={1}
                  alignItems={{ xs: "start", sm: "center" }}
                >
                  {" "}
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    variant="body1"
                    fontWeight={800}
                  >
                    Name :
                  </Typography>
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    variant="body1"
                  >
                    {user && CapitalizeFirstLetter(user["name"])}
                  </Typography>
                </Stack>
                <Stack
                  flexWrap={"wrap"}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={1}
                  alignItems={{ xs: "start", sm: "center" }}
                >
                  {" "}
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    variant="body1"
                    fontWeight={800}
                  >
                    Interest Sports :
                  </Typography>
                  {userSports && userSports.length > 0 && (
                    <Typography
                      fontFamily={"Montserrat, sans-serif"}
                      variant="body1"
                    >
                      {userSports.join(" & ")}
                    </Typography>
                  )}
                </Stack>
                <Stack
                  flexWrap={"wrap"}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={1}
                  alignItems={{ xs: "start", sm: "center" }}
                >
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    variant="body1"
                    fontWeight={800}
                  >
                    Email Address:
                  </Typography>
                  <Typography
                    margin={0}
                    fontFamily={"Montserrat, sans-serif"}
                    variant="body1"
                  >
                    {user && user["email"]}
                  </Typography>
                </Stack>

                <Stack
                  flexWrap={"wrap"}
                  direction={{ xs: "column", sm: "row", md: "row" }}
                  spacing={1}
                  alignItems={{ xs: "start", sm: "center" }}
                >
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    variant="body1"
                    fontWeight={800}
                  >
                    Phone Number:
                  </Typography>
                  {user && (
                    <Typography
                      margin={0}
                      fontFamily={"Montserrat, sans-serif"}
                      variant="body1"
                    >
                      {user["phone"] === 0 ? "-" : user["phone"]}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Box>
            <Stack direction="row" pb={2}>
              <Box>
                <Typography
                  gutterBottom
                  variant="h6"
                  color={Colors.BUTTON_COLOR}
                  fontWeight={800}
                  fontFamily={"Montserrat, sans-serif"}
                >
                  Booking History
                </Typography>
                <img
                  src={ball}
                  width={63}
                  height={63}
                  alt="ball"
                  style={{
                    position: "absolute",
                    transform: "rotate(165deg) translate(-164px, 20px)",
                  }}
                />

                <ListCard
                  userDetails={filteredData}
                  userName={user ? CapitalizeFirstLetter(user["name"]) : ""}
                />
                {filteredData.length > 0 && (
                  <Pagination
                    count={count}
                    page={page}
                    onChange={(event, val) => setPage(val)}
                    sx={{ mt: "15px" }}
                  />
                )}
              </Box>
            </Stack>
          </Stack>
        </AppContainer>
      </Box>
    </Box>
  );
}
