import { FormHelperText, InputBase, Typography, styled } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Box from "@mui/material/Box";
import Colors from "./Colors";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

interface dropdownProps {
  label: string;
  options: { value: string; label: string }[];
  placeHolder?: string;
  value?: string;
  onChange?: (value: SelectChangeEvent<string>) => void;
  error?: boolean;
  helperText?: string;
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  ".Mui-selected": {
    backgroundColor: "white",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    // backgroundColor: "white",
    BorderBottom: "1px solid black",
    fontSize: 16,
    height: "23px",
    padding: "17px 10px",
    fontWeight: "600",
    fontFamily: "Montserrat, sans-serif",

    // Use the system font instead of the default Roboto font.
    // fontFamily: ["Cormorant Garamond !important", "sans-serif"].join(","),
  },
}));
const CustomMenuItem = styled(MenuItem)(({ theme, selected }) => ({
  "&:hover": {
    backgroundColor: Colors.BUTTON_COLOR,
    fontSize: "16px",
    fontWeight: "400",
  },
  fontFamily: "Montserrat, sans-serif",

  "&.placeholder:hover": {
    backgroundColor: Colors.BUTTON_COLOR,
    fontFamily: "Montserrat, sans-serif",
  },
  "&.placeholder": {
    fontFamily: "Montserrat, sans-serif",
  },
}));

export default function DropDownComponent({
  label,
  options,
  placeHolder,
  value,
  onChange,
  error = false,
  helperText,
}: dropdownProps) {
  return (
    <Box>
      <Typography
        color={Colors.BLACK}
        fontWeight={600}
        fontSize="16px"
        mb="10px"
        fontFamily={"Montserrat, sans-serif"}
      >
        {label}
      </Typography>
      <FormControl fullWidth>
        <Select
          displayEmpty
          placeholder={placeHolder}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          onChange={onChange}
          input={<BootstrapInput />}
          sx={{
            maxWidth: 700,
            borderBottom: "1px solid black",
          }}
        >
          {placeHolder && (
            <CustomMenuItem
              style={{
                backgroundColor: "white",
                fontFamily: "Montserrat, sans-serif",
              }}
              value=""
              className={placeHolder ? "placeholder" : ""}
            >
              {placeHolder}
            </CustomMenuItem>
          )}
          {options.map((item) => (
            <CustomMenuItem key={item.value} value={item.value}>
              {item.label}
            </CustomMenuItem>
          ))}
        </Select>
        {error ? (
          <FormHelperText
            sx={{
              fontFamily: "Montserrat, sans-serif",
            }}
            error
          >
            {helperText}
          </FormHelperText>
        ) : (
          <></>
        )}
      </FormControl>
    </Box>
  );
}
