import { Box, Typography } from "@mui/material";

import Colors from "../../CommonComponents/Colors";
import EventImage from "../../assets/playzo.jpg";

export default function EventDetailsSection() {
  return (
    <Box>
      <Box>
        <img
          style={{
            // maxWidth: 850,
            width: "100%",
            borderRadius: "20px",
          }}
          src={EventImage}
          alt="event"
        />
      </Box>
      <Box padding={"15px 0px"}>
        <Typography
          fontSize={"22px"}
          fontWeight={700}
          color={Colors.BLACK}
          textAlign={"start"}
          textTransform={"none"}
          fontFamily={"lucoCleanBold"}
          sx={{
            background:
              "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            backgroundClip: "text", // This is for non-webkit browsers (optional)
            textFillColor: "transparent", // This is for non-webkit browsers (optional)
          }}
        >
          PlayZo 33 Family Fest
        </Typography>
      </Box>
      <Box borderBottom={`1px solid${Colors.BORDER}`}>
        <Typography
          marginBottom={2}
          fontSize={"18px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          fontWeight={400}
        >
          Come, Play & Win as a Family!
        </Typography>
        <Typography
          fontWeight={700}
          fontSize={"16px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          marginBottom={"10px"}
        >
          🎉 PlayZo 33 Family Fest 2024 🎉
        </Typography>

        <Typography
          paddingBottom={"10px"}
          fontWeight={300}
          fontSize={"14px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          fontStyle={"italic"}
        >
          Come, Play & Win as a Family!
        </Typography>
      </Box>

      <Box padding={"15px 0px"}>
        <Typography
          fontWeight={700}
          fontSize={"18px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          marginBottom={"10px"}
        >
          📅 Event Details:
        </Typography>
        <Typography
          fontSize={"16px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          fontWeight={400}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Date:
          </span>{" "}
          1st Dec 2024
        </Typography>
        <Typography
          fontSize={"16px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          fontWeight={400}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Time:
          </span>{" "}
          9 AM onwards
        </Typography>
        <Typography
          marginBottom={2}
          fontSize={"16px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          fontWeight={400}
        >
          <span
            style={{
              fontWeight: 700,
            }}
          >
            Venue:
          </span>{" "}
          PlayZo 33, KCP Thottam, Kumalankuttai, Erode{" "}
        </Typography>
      </Box>
      <Box>
        <Typography
          fontWeight={700}
          fontSize={"18px"}
          fontFamily={"Montserrat, sans-serif"}
          color={"#060b1e"}
          marginBottom={"10px"}
        >
          🎮 Challenges & Categories:
        </Typography>
        <Box padding={"0 15px"}>
          <Typography
            fontWeight={700}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            1. 🥄 Paani Poori Eating Challenge <br />
            2. 🍲 Taste Test{" "}
            <span
              style={{
                fontWeight: 400,
              }}
            >
              (Guess the ingredients of a dish)
            </span>{" "}
            <br />
            3. 🎮 PlayStation Showdown <br />
            4. 🎲 Strike 4{" "}
            <span
              style={{
                fontWeight: 400,
              }}
            >
              {" "}
              (Board Game){" "}
            </span>{" "}
            <br />
            5.🎲 Goblet Gobblers{" "}
            <span
              style={{
                fontWeight: 400,
              }}
            >
              (Board Game){" "}
            </span>{" "}
            <br />
            6. 🏏 Hit the Ball{" "}
            <span
              style={{
                fontWeight: 400,
              }}
            >
              {" "}
              (Cricket Nets){" "}
            </span>{" "}
            <br />
            7. ⚽ Score Goals{" "}
            <span
              style={{
                fontWeight: 400,
              }}
            >
              {" "}
              (Football){" "}
            </span>{" "}
            <br />
            8. 🏃‍♂️ Zorb Ball{" "}
            <span
              style={{
                fontWeight: 400,
              }}
            >
              {" "}
              (Obstacle Running){" "}
            </span>
            <br />
            9. 🏸 Mixed Doubles Shuttle Challenge <br />
            10. 🎉 A Fun Game Challenge <br />
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Typography
            fontWeight={700}
            fontSize={"18px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            📜 Registration & Guidelines:
          </Typography>
        </Box>

        <Box mt={3}>
          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
             Only families are allowed to compete in “PlayZo 33 Family Fest
            2024” (min. 2 members family to max. 4 members family){" "}
          </Typography>

          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
             A contesting family must have 1 married couple (2 members). The
            extra members of a family (1 or 2 members) can be children (6 yrs to
            12 yrs) or teenagers (13 to 18 yrs) or Adults (above 18 yrs).
          </Typography>

          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
             A family should take up all the 10 challenges, score points in
            each challenge, top the leader board to win the prizes.
          </Typography>

          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
             Every registering family will get discount vouchers from top
            brands in Erode and complimentary refreshments on the contest date.
          </Typography>

          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
             For more information and guidelines, download the ‘Guidelines
            Docket’ after making the payment.
          </Typography>
        </Box>

        <Box>
          <Typography
            fontWeight={700}
            fontSize={"18px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            🏆 Prizes & Gift Vouchers:
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            🥇 1st Prize: <span style={{ fontWeight: 400 }}>E Scooter</span>
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            🥈 2nd Prize:{" "}
            <span style={{ fontWeight: 400 }}>Robot Vacuum Cleaner</span>
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            🥉 3rd Prize:{" "}
            <span style={{ fontWeight: 400 }}>3-Night Vacation Voucher</span>
          </Typography>
        </Box>

        <Box>
          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
            ✨ And , 25+ more prizes for 4th, 5th, 6th and so on family in the
            leaderboard!
          </Typography>

          <Typography
            marginBottom={2}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            fontWeight={400}
          >
            🎁 Every family also receives Discount Vouchers from top brands in
            Erode!
          </Typography>
        </Box>

        <Box>
          <Typography
            fontWeight={700}
            fontSize={"18px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            💵 Registration Fees:
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            🕑 Early Bird: {""}
            <span style={{ fontWeight: 400 }}>
              Rs. 799/- (includes complimentary refreshments)
            </span>
          </Typography>
          <Typography
            fontWeight={700}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            🕑 Regular: {""}
            <span style={{ fontWeight: 400 }}>
              Rs. 999/- (includes complimentary refreshments){" "}
            </span>
          </Typography>
        </Box>

        <Box marginTop={2}>
          <Typography
            fontWeight={700}
            fontSize={"18px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            📞 For More Info:
          </Typography>

          <Typography
            fontWeight={400}
            fontSize={"16px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            📱 +91 70944 60944 | 📱 +91 99655 82355
          </Typography>

          <Typography
            fontWeight={700}
            fontSize={"18px"}
            fontFamily={"Montserrat, sans-serif"}
            color={"#060b1e"}
            marginBottom={"10px"}
          >
            Join us for a day of fun, food, and family bonding!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
