import AppContainer from "../../CommonComponents/AppContainer";
import BookEventDetails from "./BookEventDetails";
import EventDetailsSection from "./EventDetails";
import { Grid } from "@mui/material";
import OverlayText from "../../CommonComponents/CustomDateCalender/OverLayText";
import { useEffect } from "react";

const EventPage = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);
  return (
    <>
      <OverlayText />
      {/* <AppContainer> */}
      <Grid
        container
        columnGap={{ xs: 0, md: 8, lg: 11 }}
        paddingX={{ xs: 4, md: 8, lg: 19 }}
        paddingY={6}
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={7} lg={8}>
          {" "}
          <EventDetailsSection />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={3}
          sx={{ position: "sticky", top: "100px", alignSelf: "flex-start" }}
        >
          <BookEventDetails />
        </Grid>
      </Grid>
      {/* </AppContainer> */}
    </>
  );
};

export default EventPage;
