import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import assets from "../../assets";
import image1 from "./Rectangle 688.png";
import leftNavi from "./left-navi.svg";
import righttNavi from "./right-navi.svg";
import styled from "styled-components";

const {
  "image 17.png": image2,
  "image 16.png": image3,
  "image 15.png": image4,
  "Image (7).png": image5,
  "Image (8).png": image6,
} = assets;

const GalleryImages = [image1, image2, image3, image4, image5, image6];

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1000px;
`;

const ArrowButton = styled.button`
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
`;

const SlideImage = styled.img`
  width: 300px;
  height: 400px;
  object-fit: cover;
`;

const GalleryContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 0.5rem;
  overflow: hidden;
`;

const GalleryImage = styled.img`
  width: 140px;
  height: 140px;
  object-fit: cover;
  cursor: pointer;
`;

const ImageGallery = ({ images, onImageClick }: any) => {
  const visibleImages = images.slice(0, 6); // Show only the first 6 images

  return (
    <GalleryContainer>
      {visibleImages.map((image: string, index: number) => (
        <GalleryImage
          key={index}
          src={image}
          alt={`Image ${index}`}
          onClick={() => onImageClick(index)}
        />
      ))}
    </GalleryContainer>
  );
};

const GalleryWithCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleImageClick = (index: number) => {
    setCurrentIndex(index);
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === GalleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? GalleryImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <Box>
      <div
        style={{
          background: "white",
          maxWidth: "1107px",
          margin: "auto",
          padding: "0 30px",
        }}
      >
        <Box display={"flex"} justifyContent={"flex-end"}>
          <IconButton>
            <CloseIcon />
          </IconButton>
        </Box>
        <CarouselContainer>
          <ArrowButton onClick={goToPrev}>
            <img src={leftNavi} alt="left" />
          </ArrowButton>
          <SlideImage
            src={GalleryImages[currentIndex]}
            alt={`Image ${currentIndex}`}
          />
          <ArrowButton onClick={goToNext}>
            <img src={righttNavi} alt="right" />
          </ArrowButton>
        </CarouselContainer>
        <ImageGallery images={GalleryImages} onImageClick={handleImageClick} />
      </div>
    </Box>
  );
};

export default GalleryWithCarousel;
