import * as React from "react";

import { IconButton, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Colors from "../../CommonComponents/Colors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import image from "../../assets/playzo.jpg";
import routes from "../../routes/routes";

interface ModalProps {
  open: boolean;
  handleClose: () => void;
}

export default function EventComponent({ open, handleClose }: ModalProps) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(routes.EVENTS);
  };
  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "20px !important",
          maxWidth: 680,
          width: "100%",
        },
      }}
    >
      <Link to={routes.EVENTS}>
        <DialogContent
          sx={{
            padding: 0,
          }}
        >
          <Stack
            position={"absolute"}
            right={0}
            direction="column"
            spacing={3}
            alignItems="flex-end"
          >
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          </Stack>
          <Stack direction="column" spacing={3} alignItems="center">
            <img
              src={image}
              width="100%"
              style={{
                objectFit: "cover",
                maxWidth: 680,
              }}
              alt="event"
            />
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            //backgroundColor: "#d49504",
            background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
            padding: "30px 50px",
            justifyContent: "center",
          }}
        >
          <Stack
            direction="column"
            spacing={3}
            alignItems="center"
            justifyContent={"center"}
          >
            <Typography
              fontFamily="Berkshire Swash, sans-serif"
              fontSize={{ xs: "22px", sm: "28px", md: "2.2rem" }}
              color="white"
            >
              New event Launched!
            </Typography>

            <Button
              sx={{
                width: "100%",
                //maxWidth: "145px",
                borderRadius: "15px",
                textTransform: "none",
                fontSize: "18px",
                fontWeight: 600,
                padding: "10px",
                border: "1px solid",
                background: "white",

                fontFamily: "Montserrat, sans-serif",
                ":hover": {
                  // background: Colors.WHITE,
                  color: Colors.WHITE,
                  border: "1px solid",
                },
              }}
              variant="outlined"
              onClick={handleClick}
            >
              Join us!
            </Button>
          </Stack>
        </DialogActions>
      </Link>
    </Dialog>
  );
}
