export const BookingSubTypes = {
  "Turf 2.O": 1,
  "Turf 3.O": 2,
  "Turf 2.O & 3.O": 3,
  Chess: 1,
  Monopoly: 2,
  Scrabble: 3,
  "PS 1": 1,
  "PS 2": 2,
  "PS 1 & 2": 3,
  "Cricket Net": 1,
  "Bowling Machine": 1,
  "Court 5.1": 1,
  "Court 5.2": 2,
  "Court 5.3": 3,
  "Court 5.4": 4,
};

export const BookingNameTypes = {
  turf1: "Turf 2.O",
  turf2: "Turf 3.O",
  turf3: "Turf 2.O & 3.O",
  boardGame1: "Chess",
  boardGame2: "Monopoly",
  boardGame3: "Scrabble",
  playstation1: "PS 1",
  playstation2: "PS 2",
  playstation3: "PS 1 & 2",
  badminton1: "Court 5.1",
  badminton2: "Court 5.2",
  badminton3: "Court 5.3",
  badminton4: "Court 5.4",
  bowlingMachine1: "Bowling Machine",
  cricketNet1: "Cricket Net",
};
