import { Box, Divider, Typography } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Colors from "../../CommonComponents/Colors";
import RemoveIcon from "@mui/icons-material/Remove";
import { useState } from "react";

type FaqItemType = {
  paragraph: string;
  feedback: string;
};

const FaqItems: Record<
  "table1" | "table2" | "table3" | "table4" | "table5",
  FaqItemType[]
> = {
  table1: [
    {
      paragraph: "How do I book a turf or facility?",
      feedback:
        "You can book a turf or facility through our online booking system. Simply select the date and time, choose your preferred facility, and complete the payment process.",
    },
    {
      paragraph: "Can I make changes to my booking?",
      feedback:
        "Yes, you can make changes to your booking up to 24 hours before the scheduled time. After that, changes may not be allowed, and cancellation policies may apply.",
    },
    {
      paragraph: "What is the cancellation policy?",
      feedback:
        "Cancellations made at least 48 hours before the booking time will receive a full refund. Cancellations made within 48 hours may be subject to a cancellation fee.",
    },
    {
      paragraph: "How are refunds processed?",
      feedback:
        "Refunds are processed within 5-7 business days through the original payment method. If you do not receive your refund within this time, please contact our support team.",
    },
    {
      paragraph: "Do you offer any discounts for group bookings?",
      feedback:
        "Yes, we offer special discounts for group bookings. Please contact our sales team for more information on group rates and packages.",
    },
  ],
  table2: [
    {
      paragraph: "What is the cancellation policy?",
      feedback:
        "Cancellations made at least 48 hours before the booking time will receive a full refund. Cancellations made within 48 hours may be subject to a cancellation fee.",
    },
    {
      paragraph: "How do you ensure the safety of participants?",
      feedback:
        "We follow strict safety protocols, including regular inspections of our facilities, proper maintenance of equipment, and adherence to safety guidelines for all activities.",
    },
    {
      paragraph: "Are the facilities regularly sanitized?",
      feedback:
        "Yes, our facilities are regularly cleaned and sanitized to ensure a safe and hygienic environment for all participants. We follow the latest health guidelines to maintain cleanliness.",
    },
    {
      paragraph: "What should I do if I notice any safety concerns?",
      feedback:
        "If you notice any safety concerns, please report them immediately to our staff on-site. We take all safety concerns seriously and will address them promptly.",
    },
    {
      paragraph: "Is there insurance coverage for participants?",
      feedback:
        "We offer basic insurance coverage for participants as part of our services. However, we recommend that participants have their own insurance coverage as well, especially for high-risk activities.",
    },
  ],
  table3: [
    {
      paragraph: "What types of facilities do you offer?",
      feedback:
        "We offer a variety of facilities, including turf fields, indoor courts, and multi-purpose halls that can be used for various sports and events.",
    },
    {
      paragraph: "Can I book facilities for private events?",
      feedback:
        "Yes, our facilities are available for private events, such as birthday parties, corporate events, and tournaments. Please contact us for more information on availability and pricing.",
    },
    {
      paragraph: "Do you provide equipment for rent?",
      feedback:
        "Yes, we provide rental equipment such as sports gear, balls, and other necessary items. Please inquire at the time of booking or check with our on-site staff.",
    },
    {
      paragraph: "Are coaching services available?",
      feedback:
        "We offer professional coaching services for various sports. Whether you're a beginner or looking to improve your skills, our certified coaches can help you reach your goals.",
    },
    {
      paragraph: "What are your operating hours?",
      feedback:
        "Our facilities are open from 6 AM to 10 PM on weekdays and from 7 AM to 11 PM on weekends. Please check the availability of specific facilities during these hours when booking.",
    },
  ],
  table4: [
    {
      paragraph: "What payment methods do you accept?",
      feedback:
        "We accept a variety of payment methods, including credit/debit cards, online banking, and digital wallets like PayPal and Google Pay.",
    },
    {
      paragraph: "How do I receive my invoice?",
      feedback:
        "Invoices are automatically generated and sent to your registered email address once your booking is confirmed. You can also download your invoice from your account dashboard.",
    },
    {
      paragraph: "Can I get a refund if I cancel my booking?",
      feedback:
        "Yes, refunds are available as per our cancellation policy. Refunds are processed within 5-7 business days through the original payment method.",
    },
    {
      paragraph: "What should I do if I was charged incorrectly?",
      feedback:
        "If you believe you have been charged incorrectly, please contact our customer service team immediately. We will review your case and rectify any mistakes as quickly as possible.",
    },
    {
      paragraph: "Are there any additional fees I should be aware of?",
      feedback:
        "Some services may have additional fees, such as equipment rental or coaching services. These will be clearly outlined during the booking process.",
    },
  ],
  table5: [
    {
      paragraph: "What types of office cleaning services do you offer?",
      feedback:
        "We offer a comprehensive range of office cleaning services, including daily cleaning, deep cleaning, carpet cleaning, and sanitation services.",
    },
    {
      paragraph: "Do you provide cleaning supplies and equipment?",
      feedback:
        "Yes, we provide all necessary cleaning supplies and equipment as part of our service. We use eco-friendly products to ensure a safe and healthy work environment.",
    },
    {
      paragraph: "Can I schedule cleaning services outside of office hours?",
      feedback:
        "Absolutely. We offer flexible scheduling options, including after-hours and weekend cleaning, to ensure minimal disruption to your office operations.",
    },
    {
      paragraph:
        "How do you ensure the security of our office during cleaning?",
      feedback:
        "Our cleaning staff is thoroughly vetted, and we implement strict security protocols to ensure the safety of your office during cleaning. We can also work with your security team to coordinate access.",
    },
    {
      paragraph: "What are your rates for office cleaning services?",
      feedback:
        "Our rates are competitive and depend on the size of the office, the frequency of cleaning, and the specific services required. Contact us for a customized quote.",
    },
  ],
};

const tableItems = [
  { label: "General", key: "table1" },
  { label: "Trust & Safety", key: "table2" },
  { label: "Services", key: "table3" },
  { label: "Billing", key: "table4" },

  { label: "Office Cleaning", key: "table5" },
];

export default function AbouFaQ() {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);
  const [selectedTable, setSelectedTable] = useState<
    "table1" | "table2" | "table3" | "table4" | "table5"
  >("table1");

  const handleToggle = (index: number) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleTableClick = (
    table: "table1" | "table2" | "table3" | "table4" | "table5"
  ) => {
    setSelectedTable(table);
    setExpandedIndex(null); // Reset expanded state when switching tables
  };

  return (
    <>
      <Typography
        fontFamily={"Montserrat, sans-serif"}
        fontWeight={700}
        fontSize={"32px"}
        color={"black"}
        padding={{ xs: 0, sm: "0px", md: "20px", lg: 0 }}
      >
        Table Contents
      </Typography>
      <Divider
        orientation="horizontal"
        sx={{
          width: "15%",
          backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
          height: "2px", // Adjust the height to make it more visible if needed
          margin: {
            xs: " 0 0 2rem",
            sm: " 0 0 2rem",
            md: " 0 0 1rem",
            lg: " 0 0 2rem",
          },
        }}
      />
      <Box
        display={"flex"}
        flexDirection={{ xs: "column", sm: "column", md: "row" }}
        gap={{ xs: 1, sm: 1, md: 0 }}
        padding={{ xs: 0, sm: "0px", md: "20px", lg: 0 }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"20px"}
          width={{ xs: "100%", sm: "100%", md: "30%" }}
        >
          {tableItems.map((item) => (
            <Typography
              fontFamily={"Montserrat, sans-serif"}
              fontSize={"18px"}
              fontWeight={600}
              key={item.key}
              onClick={() =>
                handleTableClick(
                  item.key as
                    | "table1"
                    | "table2"
                    | "table3"
                    | "table4"
                    | "table5"
                )
              }
              sx={{
                cursor: "pointer",
                color:
                  selectedTable === item.key ? Colors.BUTTON_COLOR : "black", // Change color when selected
              }}
            >
              {item.label}
            </Typography>
          ))}
        </Box>
        <Box width={{ xs: "100%", sm: "100%", md: "70%" }}>
          {FaqItems[selectedTable].map((item, index) => (
            <Box
              onClick={() => handleToggle(index)}
              sx={{
                cursor: "pointer",
              }}
              key={index}
              width={"100%"}
              bgcolor={"white"}
              borderBottom={"1px solid black"}
              paddingBottom={"10px"}
              mb={"20px"}
            >
              <Box display={"flex"}>
                {expandedIndex === index ? (
                  <RemoveIcon
                    sx={{
                      fontSize: "25px",
                      margin: "10px 15px",
                      color: Colors.BUTTON_COLOR,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <AddIcon
                    sx={{
                      fontSize: "25px",
                      margin: "10px 15px",
                      color: Colors.BUTTON_COLOR,
                      cursor: "pointer",
                    }}
                  />
                )}
                <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                  <Typography
                    fontFamily={"Montserrat, sans-serif"}
                    color="black"
                    fontWeight={600}
                    fontSize={"18px"}
                    padding={{
                      xs: "12px 21px",
                      sm: "12px 21px",
                      md: "12px 21px",
                      lg: "12px 25px",
                    }}
                  >
                    {item.paragraph}
                  </Typography>
                  <Box
                  // sx={{
                  //   overflow: "hidden",
                  //   transition: "max-height 20s ease-out",
                  //   maxHeight: expandedIndex === index ? "200px" : "0",
                  // }}
                  >
                    {expandedIndex === index && (
                      <Typography
                        mb={"10px"}
                        color={Colors.BLACK}
                        fontWeight={"500"}
                        fontSize={"16px"}
                        padding={"0px 25px"}
                        fontFamily={"Montserrat, sans-serif"}
                      >
                        {item.feedback}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
}
