import React, { useEffect, useState } from "react";

interface TimeLeft {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

interface CountdownTimerProps {
  targetDate: string;
}

const CountDownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const calculateTimeLeft = () => {
      const target = new Date(targetDate).getTime(); // Convert to milliseconds
      const now = new Date().getTime(); // Convert to milliseconds
      const difference = target - now;

      if (difference > 0) {
        const totalSeconds = Math.floor(difference / 1000); // Total seconds remaining
        const days = Math.floor(totalSeconds / (60 * 60 * 24)); // Calculate remaining days
        const hours = Math.floor(totalSeconds / (60 * 60)) - days * 24; // Total hours - days converted to hours
        const minutes = Math.floor((totalSeconds / 60) % 60); // Minutes within the hour
        const seconds = totalSeconds % 60; // Remaining seconds

        setTimeLeft({
          days,
          hours,
          minutes,
          seconds,
        });
      } else {
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    const timerId = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timerId);
  }, [targetDate]);

  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  return (
    <div style={{ textAlign: "center" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "15px",
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        <div>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {timeLeft.days}
          </span>
          <div style={{ fontSize: "14px" }}>days</div>
        </div>
        <span>:</span>
        <div>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {formatTime(timeLeft.hours)}
          </span>
          <div style={{ fontSize: "14px" }}>hours</div>
        </div>
        <span>:</span>
        <div>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {formatTime(timeLeft.minutes)}
          </span>
          <div style={{ fontSize: "14px" }}>mins</div>
        </div>
        <span>:</span>
        <div>
          <span
            style={{
              fontSize: "24px",
              fontWeight: "bold",
            }}
          >
            {formatTime(timeLeft.seconds)}
          </span>
          <div style={{ fontSize: "14px" }}>secs</div>
        </div>
      </div>
    </div>
  );
};

export default CountDownTimer;
