import { Box, Slider, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import BoardGames from "./BaordGames_slider.jpg";
import Cafe from "../../assets/AboutPageImages/cafe_new.jpg";
import Colors from "../../CommonComponents/Colors";
import CrossFit from "../../assets/AboutPageImages/cross_fit_new.jpg";
import Turf from "./turfSlider1.jpg";
import badminton from "./badmintion_slider.jpg";
import cricketbat from "./cricket_slide.avif";
import playStation from "../../assets/AboutPageImages/PS_new.jpg";

const SliderImages = [
  { image: Turf, name: "Turf/Football" },
  { image: cricketbat, name: "Cricket / Bowling Machine" },
  { image: badminton, name: "Badminton" },
  { image: CrossFit, name: "Cross Fit" },
  { image: playStation, name: "Play Station" },
  { image: BoardGames, name: "Board Games" },
  { image: Cafe, name: "Cafeteria" },
];

export default function SliderContainer() {
  const [sliderValue, setSliderValue] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  const handleSliderChange = (event: any, newValue: any) => {
    setSliderValue(newValue);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [containerRef]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;

    if (isVisible) {
      interval = setInterval(() => {
        setSliderValue((prevValue) => {
          if (prevValue >= 100) {
            return 0; // Reset to the first image
          }
          return prevValue + 1;
        });
      }, 500); // Shorter interval for smoother effect
    }

    return () => clearInterval(interval);
  }, [isVisible]);

  // Calculate the scroll position based on the slider value
  const imageWidth = 250; // Width of each image
  const gapBetweenImages = 35; // Gap between images
  const totalScrollWidth =
    SliderImages.length * (imageWidth + gapBetweenImages);
  // const scrollPosition = (sliderValue / 210) * totalScrollWidth;

  const scrollFactor = window.innerWidth > 768 ? 210 : 100;

  const scrollPosition = (sliderValue / scrollFactor) * totalScrollWidth;
  // Calculate the actual scroll position to wrap around to the first image
  const wrappedScrollPosition = scrollPosition % totalScrollWidth;

  return (
    <Box
      ref={containerRef}
      width={"100%"}
      pb={"40px"}
      sx={{
        background: "lightblue",
        backgroundImage:
          "linear-gradient(90deg, #08CABE 0%,  #07DBF1 35%, #02A8C6 100%)",
        overflow: "hidden",
      }}
    >
      <Typography
        margin={{ xs: 0, sm: 0, md: "0px 50px" }}
        pl={"20px"}
        pt={"20px"}
        // fontSize={{ xs: "24px", sm: "24px", md: "32px" }}
        fontSize={{ xs: "24px", sm: "24px", md: "26px", lg: "50px" }}
        fontWeight={700}
        color={Colors.BLACK}
        fontFamily={"lucoCleanBold"}
      >
        Playzo33{" "}
        <span style={{ fontWeight: 700, color: "white" }}>Services</span>{" "}
      </Typography>
      <Typography
        pl={"20px"}
        margin={{ xs: 0, sm: 0, md: "0px 50px" }}
        color={"white"}
        // fontSize={{ xs: "24px", sm: "24px", md: "32px" }}
        fontSize={{ xs: "24px", sm: "24px", md: "32px" }}
        fontWeight={700}
        fontFamily={"Montserrat, sans-serif"}
      >
        <span style={{ fontWeight: 700, color: "white" }}>
          Your Go-To Place{" "}
        </span>
      </Typography>

      <Box
        mt={"60px"}
        ml={{ xs: "10px", sm: "15px", md: "46px" }}
        pb={"30px"}
        display={"flex"}
        gap={"35px"}
        sx={{
          overflowX: "hidden",
          overflowY: "hidden",
          width: "100%",
        }}
      >
        <Box
          gap={"35px"}
          sx={{
            display: "flex",
            transform: `translateX(-${wrappedScrollPosition}px)`,
            transition: "transform 1s ease-out",
            width: `${totalScrollWidth}px`,
            maxWidth: "100%",
          }}
        >
          {SliderImages.map((item, index) => (
            <Box key={index} width={"86%"} paddingLeft={"60px"}>
              <img
                src={item.image}
                height={"100%"}
                width={"250px"}
                alt={item.name}
                style={{
                  borderRadius: "30px",
                  objectFit: "cover",
                }}
              />
              <Typography
                className="home_slider_text"
                fontWeight={700}
                color={Colors.BLACK}
                textAlign={"start"}
                textTransform={"none"}
                fontFamily={"lucoCleanBold"}
                sx={{
                  transform: "rotate(-90deg) translateX(60%)",
                  marginLeft: "-300px",
                  color: "Black",
                  fontWeight: "bold",
                  fontSize: { xs: "12px", sm: "12px", md: "16px" },
                  "@media (max-width: 520px)": {
                    fontSize: "12px",
                    "-webkit-font-size": "12px",
                  },
                }}
              >
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        justifyContent={"center"}
        sx={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Slider
          style={{
            left: "0%",
            color: "black",
          }}
          value={sliderValue}
          onChange={handleSliderChange}
          sx={{
            width: "100%",
            maxWidth: { xs: "400px", md: "1400px" },
            color: "#B5B2B2",
            "& .MuiSlider-thumb": {
              width: "0px",
              height: "0px",
              borderRadius: "10px",
              background: "black",
            },
          }}
        />
      </Box>
    </Box>
  );
}
