import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

import { BookingType } from "../CommonFiles/BookingType";
import Colors from "../CommonComponents/Colors";
import latoBlack from "./pdffont/OpenSans-VariableFont_wdth,wght.ttf";
import latoBold from "./pdffont/OpenSans_SemiCondensed-Bold.ttf";
import logo from "./favicon.png";

Font.register({
  family: "Proxima",
  fonts: [
    { src: latoBlack, fontWeight: "normal" },
    { src: latoBold, fontWeight: "bold" }, // Register bold
  ],
});

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#000333",
    border: "1px solid black",
    fontFamily: "Proxima",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    textAlign: "center",
    fontSize: 24,
    marginBottom: 20,
  },
  itemName: {
    fontWeight: 700,
  },
  logo: {
    width: "300px",
    height: "100px",
    paddingBottom: "10px",
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "right",
    fontSize: "10px",
    borderTop: "1px solid black",
  },
});

const formatTimeRange = (startTime: any, endTime: any) => {
  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);

  const startHours = startDateTime.getHours();
  const endHours = endDateTime.getHours();
  const formattedStartTime = `${startHours % 12 || 12}:00`;
  const formattedEndTime = `${endHours % 12 || 12}:00 ${
    endHours < 12 ? "AM" : "PM"
  }`;

  return `${formattedStartTime} - ${formattedEndTime}`;
};

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  return formattedDate;
};

const formatDateWeek = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    weekday: "long",
  });
  return formattedDate;
};

interface User {
  phone?: string;
  name?: string;
  email?: string;
}
export default function MyDocument({
  allBookings,
  numberofPersons,
  paymentType,
  applyValue,
  payValue,
  finalAmount,
  discountAmount,
  StoredAmount,
}: any) {
  const totalAmount = allBookings.reduce(
    (accumulator: number, booking: { amount: string }) =>
      accumulator + (parseFloat(booking.amount) || 0),

    0
  );

  const [user, setUser] = useState<User | null>(null);
  const [storedFinalAmount, setStoredFinalAmount] = useState<number | null>(
    null
  );

  useEffect(() => {
    // Retrieve user data from localStorage
    const userData = localStorage.getItem("user");
    const parsedUserData = userData && JSON.parse(userData);
    if (parsedUserData) {
      setUser(parsedUserData);
    }

    // Retrieve the stored final amount from localStorage
    const storedAmount = localStorage.getItem("finalTotalAmount");
    const parsedStoredAmount = storedAmount && JSON.parse(storedAmount);
    if (parsedStoredAmount) {
      setStoredFinalAmount(parsedStoredAmount);
    }
  }, []);

  // Calculate Convenience Fees
  const convenienceFees = Math.round(totalAmount * 0.03);
  const cgst = Math.round(totalAmount * 0.09);
  const sgst = Math.round(totalAmount * 0.09);

  const subtotalAfterConvenienceFees = Math.round(
    totalAmount + convenienceFees + cgst + sgst
  );

  const hasBadmintonBooking = allBookings.some(
    (booking: any) => booking.type === BookingType.Badminton
  );

  console.log("albooking for bill no/:", allBookings);

  return (
    <Document>
      <Page
        size="A4"
        style={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid black",
          position: "relative",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: Colors.WHITE,
            borderBottom: "1px solid black",
            borderRight: "1px solid black",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              borderRight: "1px solid black",
              padding: "10px 10px",
            }}
          >
            <Image src={logo} style={styles.logo} />
          </View>

          <View
            style={{
              textAlign: "center",
              margin: 0,
              paddingRight: "50px",
            }}
          >
            <Text
              style={{
                fontSize: "50px",
                color: "black",
                margin: 0,
                fontFamily: "Proxima",
                fontWeight: "bold",
              }}
            >
              Invoice
            </Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px solid black",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          <View>
            <View
              style={{
                padding: "10px 0px 0px 0px",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 8,
                  color: Colors.BLACK,
                  fontWeight: "bold",
                  paddingBottom: "10px",
                  fontFamily: "Proxima",
                }}
              >
                BILL TO:{" "}
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                // padding: "0px 30px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  Bill No:
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  Name:
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  Phone:
                </Text>

                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  Email:
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  {" "}
                  {allBookings.length > 0
                    ? allBookings[0].uniqueBillingId
                    : null}
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  {" "}
                  {user ? user.name : null}
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  {user ? user.phone : null}
                </Text>

                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  {user ? user.email : null}
                </Text>
              </View>
            </View>
          </View>
          <View>
            <View
              style={{
                padding: "10px 0px 0px 0px",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  color: Colors.BLACK,
                  fontWeight: "bold",
                  paddingBottom: "10px",
                  fontSize: 8,
                  fontFamily: "Proxima",
                }}
              >
                COMPANY:
              </Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                // padding: "0px 30px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  Playzo33
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  39/6 KCP Thottam, Kumalan Kuttai, Erode,
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  Tamil Nadu - 638011{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "normal",
                    fontFamily: "Proxima",
                  }}
                >
                  +91 70944 60944 ,
                  <br /> +91 91088 83555
                </Text>
              </View>
            </View>
          </View>

          <View>
            <View
              style={{
                padding: "10px 0px 0px 0px",
              }}
            >
              <Text
                style={{
                  textAlign: "left",
                  fontSize: 8,
                  color: Colors.BLACK,
                  fontWeight: "bold",
                  paddingBottom: "10px",
                  fontFamily: "Proxima",
                }}
              ></Text>
            </View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                // padding: "0px 30px",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "bold",
                    fontFamily: "Proxima",
                  }}
                >
                  GSTIN{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "bold",
                    fontFamily: "Proxima",
                  }}
                >
                  Nature of business:
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontWeight: "bold",
                    fontFamily: "Proxima",
                  }}
                >
                  Partner's name:
                </Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  paddingBottom: "10px",
                }}
              >
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  33AAXFP7708F1ZU
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  {" "}
                  Sports And Recreational Activities{" "}
                </Text>
                <Text
                  style={{
                    fontSize: "8px",
                    color: Colors.BLACK,
                    fontFamily: "Proxima",
                  }}
                >
                  1.K.P.SHIVA SHANKAR, 2.K.P.SARAVANAN{" "}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ padding: "30px 30px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              borderBottom: `1px solid ${Colors.BACKGROUND_COLOR}`,
              paddingBottom: "5px",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "20%",
                color: Colors.BLACK,
                textTransform: "capitalize",
                padding: "10px 0",
                fontFamily: "Proxima",
              }}
            >
              Service
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "20%",
                textTransform: "capitalize",
                color: Colors.BLACK,
                padding: "10px 0",
                fontFamily: "Proxima",
              }}
            >
              Type
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "20%",
                textTransform: "capitalize",
                color: Colors.BLACK,

                padding: "10px 0",
                fontFamily: "Proxima",
              }}
            >
              Date
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "bold",
                width: "20%",
                textTransform: "capitalize",
                color: Colors.BLACK,

                padding: "10px 0",
                fontFamily: "Proxima",
              }}
            >
              Time
            </Text>
            {allBookings.some(
              (item: { type: BookingType }) =>
                item.type === BookingType.Badminton
            ) ? (
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "20%",
                  textTransform: "capitalize",
                  color: Colors.BLACK,

                  padding: "10px 0",
                  fontFamily: "Proxima",
                }}
              >
                Person
              </Text>
            ) : (
              ""
            )}

            <Text
              style={{
                fontSize: "12px",
                fontWeight: 700,
                width: "20%",
                textTransform: "capitalize",
                color: Colors.BLACK,

                padding: "10px 0",
                fontFamily: "Proxima",
              }}
            >
              Day
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontWeight: 700,
                width: "20%",
                textTransform: "capitalize",
                color: Colors.BLACK,

                padding: "10px 0",
                fontFamily: "Proxima",
              }}
            >
              Amount
            </Text>
          </View>
          {allBookings.map((item: any, index: any) => (
            <View
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                borderBottom: `1px solid ${Colors.BACKGROUND_COLOR}`,
                paddingBottom: "5px",
                paddingTop: "10px",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              {" "}
              <Text
                style={{
                  fontSize: "12px",
                  width: "20%",
                  color: Colors.BACKGROUND_COLOR,
                  textTransform: "capitalize",
                  fontFamily: "Proxima",
                  fontWeight: "normal",
                }}
              >
                {item.type}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  width: "20%",
                  color: Colors.BLACK,
                  textTransform: "capitalize",
                  fontFamily: "Proxima",
                }}
              >
                {item.name}{" "}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  width: "20%",
                  color: Colors.BLACK,
                  fontFamily: "Proxima",
                }}
              >
                {formatDate(item.startDate)}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "normal",
                  width: "20%",
                  color: Colors.BLACK,
                  fontFamily: "Proxima",
                  textAlign: "center",
                }}
              >
                {formatTimeRange(item.startTime, item.endTime)}
              </Text>
              {item.numberOfPersons && (
                <Text
                  style={{
                    fontSize: "12px",
                    fontWeight: "normal",
                    width: "20%",
                    color: Colors.BLACK,
                    textAlign: "center",
                    fontFamily: "Proxima",
                  }}
                >
                  {item.numberOfPersons}
                </Text>
              )}
              {hasBadmintonBooking &&
                (item.type === BookingType.Turf ||
                  item.type === BookingType.Playstaion) && (
                  <Text
                    style={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      width: "20%",
                      color: Colors.BLACK,
                      textAlign: "center",
                      fontFamily: "Proxima",
                    }}
                  >
                    {" "}
                  </Text>
                )}
              <Text
                style={{
                  fontSize: "13px",
                  fontWeight: "normal",
                  width: "20%",
                  textTransform: "capitalize",
                  color: Colors.BLACK,

                  padding: "10px 0",
                  fontFamily: "Proxima",
                }}
              >
                {formatDateWeek(item.startDate)}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  width: "20%",
                  color: Colors.BLACK,
                  fontFamily: "Proxima",
                }}
              >
                {item.amount} RS
              </Text>
            </View>
          ))}
          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                display: "flex",
                color: Colors.BLACK,
                borderBottom: `2px solid ${Colors.BLACK}`,
                fontFamily: "Proxima",
              }}
            >
              Total:{" "}
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontFamily: "Proxima",

                color: Colors.BLACK,
                borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
              }}
            >
              {totalAmount} Rs
              {/* {subtotalAfterConvenienceFees} RS */}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "12px",
                fontWeight: "normal",
                display: "flex",
                color: Colors.BLACK,
                fontFamily: "Proxima",

                borderBottom: `2px solid ${Colors.BLACK}`,
              }}
            >
              Online Convenience Fee (3%):{" "}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: Colors.BLACK,
                fontFamily: "Proxima",

                borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
              }}
            >
              {convenienceFees} Rs
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                display: "flex",
                color: Colors.BLACK,
                fontFamily: "Proxima",

                borderBottom: `2px solid ${Colors.BLACK}`,
              }}
            >
              CGST (9%):{" "}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: Colors.BLACK,
                fontFamily: "Proxima",

                borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
              }}
            >
              {cgst} Rs
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                display: "flex",
                color: Colors.BLACK,
                fontFamily: "Proxima",

                borderBottom: `2px solid ${Colors.BLACK}`,
              }}
            >
              SGST (9%):{" "}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: Colors.BLACK,
                fontFamily: "Proxima",

                borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
              }}
            >
              {sgst} Rs
            </Text>
          </View>

          {/* sub total */}

          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                display: "flex",
                color: Colors.BLACK,
                borderBottom: `2px solid ${Colors.BLACK}`,
                fontFamily: "Proxima",
              }}
            >
              Sub Total:{" "}
            </Text>
            <Text
              style={{
                fontSize: "12px",
                fontFamily: "Proxima",

                color: Colors.BLACK,
                borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
              }}
            >
              {subtotalAfterConvenienceFees} RS
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "flex-end",
              paddingTop: "10px",
            }}
          >
            <Text
              style={{
                fontSize: "10px",
                fontWeight: "normal",
                display: "flex",
                color: Colors.BLACK,
                fontFamily: "Proxima",
                borderBottom: `2px solid ${Colors.BLACK}`,
              }}
            >
              Paid Amount:{""}
            </Text>
            <Text
              style={{
                fontSize: "10px",
                color: Colors.BLACK,
                fontFamily: "Proxima",
                borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
              }}
            >
              {storedFinalAmount} Rs
            </Text>
          </View>

          {/* if advance means show remiaing amount */}
          {paymentType === "advance" && (
            <View
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingTop: "10px",
              }}
            >
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "normal",
                  display: "flex",
                  color: Colors.BLACK,
                  fontFamily: "Proxima",
                  borderBottom: `2px solid ${Colors.BLACK}`,
                }}
              >
                Grand Total:{""}
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  color: Colors.BLACK,
                  fontFamily: "Proxima",
                  borderBottom: `2px solid ${Colors.BACKGROUND_COLOR}`,
                }}
              >
                {""}{" "}
                {storedFinalAmount != null
                  ? storedFinalAmount - subtotalAfterConvenienceFees
                  : 0}{" "}
                Rs
              </Text>
            </View>
          )}
        </View>
        {paymentType === "advance" && (
          <View
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              position: "absolute",
              bottom: 30,
              paddingLeft: "30px",
            }}
          >
            <Text
              style={{
                fontSize: 12,
                fontWeight: 700,
                color: Colors.BLACK,
                fontFamily: "Proxima",
                textDecoration: "underline",
              }}
            >
              Important Note:-
            </Text>

            <Text
              style={{
                fontSize: 8, // Corrected to a number
                fontWeight: "normal",
                color: Colors.BLACK,
                paddingTop: "10px",
                fontFamily: "Proxima",
                width: "50%",
              }}
            >
              You have paid 30% of the total in advance, and the remaining fee
              has to be paid in Playzo Front Office by showing the booking
              receipt generated post the payment.
            </Text>
          </View>
        )}
        <View
          style={{
            display: "flex",
            width: "100%",
            borderTop: "1px solid black",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            position: "absolute",
            bottom: 0,
          }}
        >
          <View
            style={{
              padding: "50px",
              borderLeft: "1px solid black",
              maxWidth: 300,
            }}
          >
            <Text
              style={{
                color: "black",
                textAlign: "center",
                fontFamily: "Proxima",
                fontWeight: "bold",
              }}
            >
              THANK YOU <br />
            </Text>
          </View>
        </View>{" "}
      </Page>
    </Document>
  );
}
