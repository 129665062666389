import axiosInstance from "./CreateAxiosInstance";
import { handleApiError } from "../Utils/ApiUtils";

export default class OffersApi {

  public static async getOfferById(offerType: string) {
    try {
      const detail = await axiosInstance.get<any>(`/offers/${offerType}`);
      return detail.data;
    } catch (e) {
      throw handleApiError(e, 'Failed to get offers');
    }
  }

  public static async getAll() {
    try {
      const details = await axiosInstance.get<any[]>('/offers');
      return details.data;
    } catch (e) {
      throw handleApiError(e, 'Failed to get all details');
    }
  }

}
