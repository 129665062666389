import { Backdrop, Box, CircularProgress } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";

import Colors from "../../CommonComponents/Colors";

interface CustomSliderProps {
  slides: ReactNode[];
  autoplayInterval: number;
  nextSlide: any;
  currentSlide: any;
  handleBulletClick: any;
}

const CustomSlider = ({
  slides,
  autoplayInterval,
  nextSlide,
  currentSlide,
  handleBulletClick,
}: CustomSliderProps) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, autoplayInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [autoplayInterval, nextSlide]);

  const [showSkeleton, setShowSkeleton] = useState(true);
  const [loading, setLoading] = useState(true); // Set initial loading state to true

  useEffect(() => {
    // Simulate loading delay with setTimeout
    const timer = setTimeout(() => {
      setLoading(false); // After timeout, set loading state to false
    }, 3000); // Adjust timeout duration as needed

    return () => clearTimeout(timer); // Cleanup function to clear the timer
  }, []);

  useEffect(() => {
    // After 4 seconds, set showSkeleton to false to display the actual images
    const timer = setTimeout(() => {
      setShowSkeleton(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          overflow: "hidden",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            transform: `translateX(-${currentSlide * 100}%)`,
            height: "100%",
          }}
        >
          <>
            {slides.map((slide, index) => (
              <Box
                key={index}
                sx={{
                  flex: "0 0 100%",
                  boxSizing: "border-box",
                  width: "100%",
                  overflow: "hidden",
                  position: "relative",
                  height: "100%",
                  objectFit: "cover",
                }}
              >
                <Backdrop
                  open={loading}
                  sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                    color: "#fff",
                  }}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                  component={"image"}
                  sx={{
                    backgroundImage: `url(${slide})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    objectFit: "cover",
                    overflow: "hidden",
                    display: "flex",
                    height: "100%",
                  }}
                >
                  {slide}
                </Box>
              </Box>
            ))}
          </>
          {/* )} */}
        </Box>
      </Box>
      <Box
        mt={1}
        border="1px solid black"
        display="flex"
        justifyContent="center"
        bgcolor="black"
      >
        {slides.map((_, index) => (
          <Box
            key={index}
            sx={{
              position: "relative",
              fontSize: "14px",
              color: currentSlide === index ? "red" : "gray",
              cursor: "pointer",
              width: slides.length === 2 ? "100%" : "100%",
            }}
            onClick={() => handleBulletClick(index)}
          >
            <Box
              sx={{
                fontSize: "14px",
                color: currentSlide === index ? "red" : "gray",
                cursor: "pointer",
                position: "absolute",
                border:
                  currentSlide === index ? `2px solid ${Colors.BLACK}` : "none",
                width: slides.length === index ? "100%" : "100%",
              }}
            />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default CustomSlider;
