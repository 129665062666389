import { Box, Divider, Typography, useMediaQuery } from "@mui/material";

import AppContainer from "../../CommonComponents/AppContainer";
import Colors from "../../CommonComponents/Colors";
import OverlayText from "../../CommonComponents/CustomDateCalender/OverLayText";
import aboutPage from "../../assets/AboutPageImages";
import layer from "../Terms&conditions/Layer.svg";
import styled from "styled-components";
import { useEffect } from "react";

const { "cookies.jpg": bg } = aboutPage;

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const BannerImage = styled.img`
  @media (min-width: 300px) {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    width: 100%;
    height: 282px;
    object-fit: cover;
  }
  @media (min-width: 820px) {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }

  @media (min-width: 912px) {
    height: 379px;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 1200px) {
    width: 100%;
    max-height: 400px;
    object-fit: cover;
  }
`;

const StyledImage5 = styled.img`
  @media (min-width: 300px) {
    max-height: 250px;
    object-fit: cover;
    position: absolute;
    transform: translate(550%, 0px);
  }

  @media (min-width: 768px) {
    max-height: 400px;
    object-fit: cover;
    position: absolute;
    transform: translate(679%, -117px);
  }
  @media (min-width: 820px) {
    max-height: 400px;
    object-fit: cover;
    position: absolute;
    transform: translate(726%, -117px);
  }

  @media (min-width: 900px) {
    max-height: 400px;
    object-fit: cover;
    position: absolute;
    transform: translate(950%, -21px);
  }

  @media (min-width: 1200px) {
    max-height: 400px;
    object-fit: cover;
    position: absolute;
    transform: translate(1300%, -21px);
  }
`;

export default function TermsandConditions() {
  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, []);

  const isHideBannerImage = useMediaQuery(
    "(min-width: 280px) and (max-width: 900px)"
  );

  return (
    <>
      <OverlayText />
      <Box mb={"40px"}>
        <Container>
          {isHideBannerImage ? null : <BannerImage src={bg} alt="banner" />}
          <Box
            sx={{
              background:
                "#15b5fc linear-gradient(45deg, #2196f3 30%, #21cbf3 70%)",
              maxWidth: { xs: "100%", sm: "100%", md: "560px", lg: "800px" },
              width: { xs: "100%", sm: "100%" },
              height: { xs: "250px", sm: "282px", md: "380px", lg: "379px" },
              position: "absolute",
            }}
          >
            {" "}
          </Box>
          {isHideBannerImage ? null : (
            <StyledImage5 src={layer} alt="background" />
          )}
        </Container>
        <Box position={"absolute"} left={"9%"} pt={"30px"}>
          <Typography
            display={"flex"}
            flexDirection={"column"}
            gap={"10px"}
            position={"absolute"}
            top={{ xs: "400%", sm: "450%", md: "-730%" }}
            lineHeight={1}
            fontFamily={"lucoCleanBold"}
            fontWeight={500}
            fontSize={{ xs: "35px", sm: "50px", md: "65px" }}
            color={"Black"}
            textTransform={"uppercase"}
          >
            Cookies <br />
            <span
              style={{
                color: "white",
                paddingTop: "10px",
              }}
            >
              Policy
            </span>
          </Typography>
        </Box>

        <AppContainer
          mt={{ xs: "18rem", sm: "20rem", md: "2rem" }}
          padding={0}
          maxWidth={1180}
        >
          <Box padding={"0 20px"} display={"flex"} flexDirection={"column"}>
            <Box>
              <Typography
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"24px"}
                fontWeight={600}
                pb={"5px"}
              >
                Cookie policy
              </Typography>
              <Divider
                orientation="horizontal"
                sx={{
                  width: { xs: "30%", md: "10%" },
                  backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                  height: "2px",
                }}
              />
              <Typography
                mt={2}
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"16px"}
                fontWeight={400}
              >
                Cookies and Other Tracking Technologies:
              </Typography>
              <Typography
                mt={2}
                fontSize={"16px"}
                fontFamily={"Montserrat, sans-serif"}
                fontWeight={400}
              >
                Playzo33 uses cookies and tracking pixels to collect information
                that helps us enhance our service.
              </Typography>
            </Box>

            <Box>
              <li
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "20px",
                }}
              >
                For performance and analytics. These cookies and pixels collect
                information on how users
              </li>
              <Typography
                fontFamily={"Montserrat, sans-serif"}
                fontSize={"16px"}
                fontWeight={400}
              >
                interact with the Services and enable us to improve how we
                operate. For example, we use Google Analytics cookies to help us
                understand how visitors arrive at and browse our website to
                identify areas for improvement such as navigation, user
                experience, and marketing campaigns.
              </Typography>
            </Box>
            <Box>
              <li
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "Montserrat, sans-serif",
                  marginTop: "20px",
                }}
              >
                Targeting Cookies or Advertising Cookies. These cookies collect
                information about your browsing habits in order to make
                advertising relevant to you and your interests. They remember
                the websites you have visited and that information is shared
                with other parties such as advertising technology service
                providers and advertisers.
              </li>
              <br />
              <li
                style={{
                  fontSize: "16px",
                  fontWeight: 400,
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                Social media cookies. These cookies are used when you share
                information using a social media sharing button or “like” button
                on our websites or you link your account or engage with our
                content on or through a social media site. The social network
                will record that you have done this.
              </li>
            </Box>
            <Typography
              padding={"0 10px"}
              fontFamily={"Montserrat, sans-serif"}
              fontSize={"18px"}
              fontWeight={400}
              mt={"20px"}
            >
              This information may be linked to targeting/advertising
              activities.
              <br />
            </Typography>
            <Typography
              fontFamily={"Montserrat, sans-serif"}
              pb={"5px"}
              fontSize={"24px"}
              fontWeight={600}
              mt={"20px"}
            >
              How can you opt-out?
            </Typography>
            <Divider
              orientation="horizontal"
              sx={{
                width: { xs: "49%", md: "15%" },
                backgroundColor: Colors.BUTTON_COLOR, // Set the background color of the divider
                height: "2px",
              }}
            />
            <Typography
              fontFamily={"Montserrat, sans-serif"}
              padding={"0 10px"}
              fontSize={"18px"}
              fontWeight={400}
              mt={"20px"}
            >
              To opt-out of our use of cookies, you can instruct your browser,
              by changing its options, to stop accepting cookies or to prompt
              you before accepting a cookie from websites you visit.
            </Typography>

            <br />

            <Typography
              fontFamily={"Montserrat, sans-serif"}
              padding={"0 10px"}
              fontSize={"16px"}
              fontWeight={500}
            >
              Please contact{" "}
              <a
                style={{
                  color: "black",
                  fontWeight: 600,
                }}
                href="mailto:playzo.bookings@gmail.com"
              >
                playzo.bookings@gmail.com
              </a>{" "}
              if you have any questions.
            </Typography>
          </Box>
        </AppContainer>
      </Box>
    </>
  );
}
