import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useState } from "react";
import UserApi from "../../api/UserApi";
import Colors from "../../CommonComponents/Colors";
import ModalComponent from "../../CommonComponents/CustomDateCalender/ModalComponent";
import CustomLabel from "../../CommonComponents/CustomLabel";
import OTPModal from "./OTPModal";

interface loginProps {
  handleClose?: () => void;
  open: any;
  setOpenForgetModal: any;
}

export default function ForgetPassword({
  handleClose,
  open,
  setOpenForgetModal,
}: loginProps) {
  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [usersNot, setUsersNot] = useState(false);

  const [emailValue, setEmailValue] = useState("");

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(false);
  };

  const validateEmail = (input: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const onSubmit = () => {
    if (!validateEmail(email)) {
      setIsValidEmail(true);
      return;
    }
    try {
      const data = UserApi.sendOtp({ email: email })
        .then((dataVal) => {
          setEmailValue(dataVal.email);
          setShowOTPModal(true);
        })
        .catch((error) => {
          setUsersNot(true);
        });
      console.log("data", data);
    } catch (error) {
      alert(error);
      console.log("error", error);
    }
  };

  const handleOTPModalClose = () => {
    setShowOTPModal(false);
  };

  const HandleCloseandClear = () => {
    handleClose?.();
    setEmail("");
    setIsValidEmail(false);
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        sx={{
          borderRadius: "42px !important",
          "& .MuiDialog-paper": {
            borderRadius: "42px !important",
          },
        }}
      >
        <DialogContent
          style={{
            background: Colors.BACKGROUND_COLOR,
          }}
        >
          <Stack direction="row" justifyContent="end">
            <IconButton onClick={HandleCloseandClear}>
              <CloseIcon sx={{ color: Colors.WHITE, fontSize: "30px" }} />
            </IconButton>
          </Stack>
          <Stack direction="column" spacing={3} padding={2}>
            <Box>
              <CustomLabel color={Colors.WHITE} mb={1}>
                Email
              </CustomLabel>
              <TextField
                id="outlined-basic"
                variant="outlined"
                sx={{
                  backgroundColor: Colors.WHITE,
                  width: "100%",
                  borderRadius: "8px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "8px",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Montserrat, sans-serif !important",
                    fontWeight: 600,
                  },
                }}
                placeholder="Enter your email"
                type="email"
                required
                value={email}
                onChange={handleEmailChange}
                error={!!isValidEmail}
                InputProps={{
                  endAdornment: isValidEmail && (
                    <ErrorOutlineIcon
                      color="error"
                      style={{ marginRight: "8px" }}
                    />
                  ),
                }}
              />
              <span
                style={{
                  color: "#d32f2f",
                  fontSize: "12px",
                  fontFamily: "Montserrat, sans-serif ",
                }}
              >
                {isValidEmail ? "Please enter a valid email address" : ""}
              </span>
            </Box>

            <Button
              type="button"
              sx={{
                padding: "12px 20px",
                textTransform: "none",
                fontSize: "16px",
                minWidth: "110px",
                fontWeight: "400",
                border: "2px solid #15B5FC",
                borderRadius: "50px",
                letterSpacing: "1.6px",
                background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                color: Colors.WHITE,
                ":hover": {
                  background: Colors.WHITE,
                  color: Colors.BUTTON_COLOR,
                  border: "2px solid #15B5FC",
                },
                fontFamily: "Montserrat', sans-serif",
              }}
              onClick={onSubmit}
            >
              Send Verification Link
            </Button>

            <Button
              type="button"
              sx={{
                padding: "12px 20px",
                textTransform: "none",
                fontSize: "16px",
                minWidth: "110px",
                fontWeight: "400",
                border: "2px solid #15B5FC",
                borderRadius: "50px",
                letterSpacing: "1.6px",
                background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
                color: Colors.WHITE,
                ":hover": {
                  background: Colors.WHITE,
                  color: Colors.BUTTON_COLOR,
                  border: "2px solid #15B5FC",
                },
                fontFamily: "Montserrat', sans-serif",
              }}
              onClick={HandleCloseandClear}
            >
              Back
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <>
        {showOTPModal && (
          <OTPModal
            open={showOTPModal}
            handleClose={handleOTPModalClose}
            setOpenForgetModal={setOpenForgetModal}
            email={emailValue}
          />
        )}

        <ModalComponent
          open={usersNot}
          handleClose={() => setUsersNot(false)}
          text={"User not exists with given email!"}
          headingText={"User Not Exists"}
        />
      </>
    </>
  );
}
