import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import Colors from "../../CommonComponents/Colors";
import CountDownTimer from "./CountDownTimer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ShareIcon from "@mui/icons-material/Share";

const BookEventDetails = () => {
  const targetDate = "2024-12-01T09:00:00";

  const handleViewClick = () => {
    window.location.href =
      "https://www.google.com/maps/place/Playzo+33/@11.3347979,77.6974445,17z/data=!3m1!4b1!4m6!3m5!1s0x3ba96f67fcd33c3d:0x6d17503b1dad5cfb!8m2!3d11.3347979!4d77.7000194!16s%2Fg%2F11h585c6l6?entry=tts&g_ep=EgoyMDI0MTEwNS4wIPu8ASoASAFQAw%3D%3D";
  };

  return (
    <Stack direction="column" spacing={2.5} paddingY={3}>
      <Link to="https://sportswander.com/event/MTEw/PlayZo-33-Family-Fest-2024">
        <Button
          fullWidth
          sx={{
            padding: "12px 20px",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: 600,
            border: "2px solid #15B5FC",
            borderRadius: "50px",
            letterSpacing: "1.6px",
            background: "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)",
            color: Colors.WHITE,
            ":hover": {
              background: Colors.WHITE,
              color: Colors.BUTTON_COLOR,
              border: "2px solid #15B5FC",
            },
            fontFamily: "Montserrat, sans-serif",
          }}
          endIcon={<KeyboardDoubleArrowRightIcon sx={{ fontSize: "20px" }} />}
          // onClick={handleClick}
        >
          Go to this page and book
        </Button>
      </Link>
      <Typography
        fontSize="16px"
        textAlign="center"
        fontFamily="Montserrat, sans-serif"
      >
        Event Starts in
      </Typography>
      <CountDownTimer targetDate={targetDate} />
      <Typography
        fontFamily="Montserrat, sans-serif"
        fontWeight={600}
        fontSize="20px"
        paddingTop={3}
        paddingBottom={1}
      >
        Event Details:
      </Typography>

      <Stack direction="row" spacing={1}>
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          Mode :{" "}
        </Typography>
        <Typography fontFamily="Montserrat, sans-serif">Physical</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          Date :{" "}
        </Typography>
        <Typography fontFamily="Montserrat, sans-serif">1 DEC 2024</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          Timing :{" "}
        </Typography>
        <Typography fontFamily="Montserrat, sans-serif">
          {" "}
          9:00 AM - 4:00 PM
        </Typography>
      </Stack>
      <Typography
        fontFamily="Montserrat, sans-serif"
        fontWeight={600}
        fontSize="20px"
        paddingTop={3}
        paddingBottom={1}
      >
        Venue Details:
      </Typography>
      <Stack direction="row" spacing={1}>
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          Place :{" "}
        </Typography>
        <Typography fontFamily="Montserrat, sans-serif">Playzo 33</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          District :{" "}
        </Typography>
        <Typography fontFamily="Montserrat, sans-serif">Erode</Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          Address :{" "}
          <span style={{ fontWeight: 100 }}>
            39/6, Selvam Nagar, KCP Thottam, , Kumalan Kuttai, Erode, Tamil Nadu
            638011
          </span>
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography
          fontFamily="Montserrat, sans-serif"
          fontWeight={600}
          fontSize="16px"
        >
          Location :
        </Typography>
        <Button
          sx={{
            padding: "8px 10px",
            textTransform: "none",
            fontSize: "14px",
            minWidth: "110px",
            fontWeight: 600,
            borderRadius: "50px",
            letterSpacing: "1.6px",
            background: "#edf1f5",
            color: "black",
            fontFamily: "Montserrat, sans-serif",
          }}
          onClick={handleViewClick}
          startIcon={<LocationOnIcon />}
        >
          View in Maps
        </Button>
      </Stack>
    </Stack>
  );
};

export default BookEventDetails;
