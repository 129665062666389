const S3_BASE_URL = process.env.REACT_APP_S3_BASE_URL
const path = 'assets'

const assets = {
    "doodle-soccer-ball_1034-741": `${S3_BASE_URL}/${path}/doodle-soccer-ball_1034-741.avif`,
    "img_531194.png": `${S3_BASE_URL}/${path}/img_531194.png`,
    "pexels-ben-muk-5488874.jpg.crdownload": `${S3_BASE_URL}/${path}/pexels-ben-muk-5488874.jpg.crdownload`,
    "pexels-photo-114296.jpeg": `${S3_BASE_URL}/${path}/pexels-photo-114296.jpeg`,
    "pexels-photo-3660204.jpeg": `${S3_BASE_URL}/${path}/pexels-photo-3660204.jpeg`,
    "pexels-photo-4966378.jpeg": `${S3_BASE_URL}/${path}/pexels-photo-4966378.jpeg`,
    "pexels-photo-8007173.jpeg": `${S3_BASE_URL}/${path}/pexels-photo-8007173.jpeg`,
    "pexels-photo-13710137.jpeg": `${S3_BASE_URL}/${path}/pexels-photo-13710137.jpeg`,
    "5360cd03c5ae030e6ab555508a65f614.jpg": `${S3_BASE_URL}/${path}/5360cd03c5ae030e6ab555508a65f614.jpg`,
    "278453372_302010395428882_1782198866577334663_n.jpg": `${S3_BASE_URL}/${path}/278453372_302010395428882_1782198866577334663_n.jpg`,
    "292237898_605074144217428_6740391706468899652_n.jpg": `${S3_BASE_URL}/${path}/292237898_605074144217428_6740391706468899652_n.jpg`,
    "296017376_1229442621218307_3144906300765873571_n.jpg": `${S3_BASE_URL}/${path}/296017376_1229442621218307_3144906300765873571_n.jpg`,
    "307209848_504498594836255_6289868240102465405_n.jpg": `${S3_BASE_URL}/${path}/307209848_504498594836255_6289868240102465405_n.jpg`,
    "bat-and-ball-doodle-icon-of-cricket-vector.jpg": `${S3_BASE_URL}/${path}/bat-and-ball-doodle-icon-of-cricket-vector.jpg`,
    "Birmingham-03-1-1024x561.jpg": `${S3_BASE_URL}/${path}/Birmingham-03-1-1024x561.jpg`,
    "cookies.jpg": `${S3_BASE_URL}/${path}/cookies.jpg`,
    "istockphoto-1402596991-2048x2048.jpg": `${S3_BASE_URL}/${path}/istockphoto-1402596991-2048x2048.jpg`,
    "pexels-ben-muk-5488874 (1).jpg": `${S3_BASE_URL}/${path}/pexels-ben-muk-5488874 (1).jpg`,
    "pexels-gonzalo-facello-1432039.jpg": `${S3_BASE_URL}/${path}/pexels-gonzalo-facello-1432039.jpg`,
    "pexels-guduru-ajay-bhargav-863988.jpg": `${S3_BASE_URL}/${path}/pexels-guduru-ajay-bhargav-863988.jpg`,
    "pexels-karolina-grabowska-4966368.jpg": `${S3_BASE_URL}/${path}/pexels-karolina-grabowska-4966368.jpg`,
    "pexels-pixabay-46798.jpg": `${S3_BASE_URL}/${path}/pexels-pixabay-46798.jpg`,
    "pexels-pixabay-209977.jog": `${S3_BASE_URL}/${path}/pexels-pixabay-209977.jpg`,
    "pexels-pixabay-269630.jpg": `${S3_BASE_URL}/${path}/pexels-pixabay-269630.jpg`,
    "pexels-thiago-kai-9381737.jpg": `${S3_BASE_URL}/${path}/pexels-thiago-kai-9381737.jpg`,
    "pexels-vazhnik-7864443.jpg": `${S3_BASE_URL}/${path}/pexels-vazhnik-7864443.jpg`,
    "Playzo.jpg": `${S3_BASE_URL}/${path}/Playzo.jpg`,
    "ps4-controller-vector-png-1.jpg": `${S3_BASE_URL}/${path}/ps4-controller-vector-png-1.jpg`,
    "shuttlecock-logo-icon-design-free-vector.jpg": `${S3_BASE_URL}/${path}/shuttlecock-logo-icon-design-free-vector.jpg`,
    "159415.svg": `${S3_BASE_URL}/${path}/159415.svg`,
    "Aboutus.svg": `${S3_BASE_URL}/${path}/Aboutus.svg`,
    "Badmin_logo.svg": `${S3_BASE_URL}/${path}/Badmin_logo.svg`,
    "Badmintonslide1.svg": `${S3_BASE_URL}/${path}/Badmintonslide1.svg`,
    "Board_logo.svg": `${S3_BASE_URL}/${path}/Board_logo.svg`,
    "BoardGameSlide1.svg": `${S3_BASE_URL}/${path}/BoardGameSlide1.svg`,
    "Bowling_logo.svg": `${S3_BASE_URL}/${path}/Bowling_logo.svg`,
    "BowlingSlide1.svg": `${S3_BASE_URL}/${path}/BowlingSlide1.svg`,
    "Cafe_logo.svg": `${S3_BASE_URL}/${path}/Cafe_logo.svg`,
    "CafeteriaSlide1.svg": `${S3_BASE_URL}/${path}/CafeteriaSlide1.svg`,
    "careers-images.svg": `${S3_BASE_URL}/${path}/careers-images.svg`,
    "Cricket_logo.svg": `${S3_BASE_URL}/${path}/Cricket_logo.svg`,
    "CricketNetSlide1.svg": `${S3_BASE_URL}/${path}/CricketNetSlide1.svg`,
    "Cross_logo.svg": `${S3_BASE_URL}/${path}/Cross_logo.svg`,
    "CrossFitslide1.svg": `${S3_BASE_URL}/${path}/CrossFitslide1.svg`,
    "Layer.svg": `${S3_BASE_URL}/${path}/Layer.svg`,
    "LeftArrow.svg": `${S3_BASE_URL}/${path}/LeftArrow.svg`,
    "Party_logo.svg":  `${S3_BASE_URL}/${path}/Party_logo.svg`,
    "PartyCenterSlide1.svg": `${S3_BASE_URL}/${path}/PartyCenterSlide1.svg`,
    "Play_logo.svg": `${S3_BASE_URL}/${path}/Play_logo.svg`,
    "Playzo (1).svg": `${S3_BASE_URL}/${path}/Playzo (1).svg`,
    "plyaStationSlide.svg": `${S3_BASE_URL}/${path}/plyaStationSlide.svg`,
    "ServiceHeader.svg": `${S3_BASE_URL}/${path}/ServiceHeader.svg`,
    "ServicePara.svg": `${S3_BASE_URL}/${path}/ServicePara.svg`,
    "ServicePara1.svg": `${S3_BASE_URL}/${path}/ServicePara1.svg`,
    "Turf_logo.svg": `${S3_BASE_URL}/${path}/Turf_logo.svg`,
    "TurfSlide1.svg": `${S3_BASE_URL}/${path}/TurfSlide1.svg`,
    "playzo.mp4":`${S3_BASE_URL}/${path}/playzo.mp4`,
    "about-banner.png": `${S3_BASE_URL}/${path}/about-banner.png`,
    "ball 1.png": `${S3_BASE_URL}/${path}/ball 1.png`,
    "ball 2.png": `${S3_BASE_URL}/${path}/ball 2.png`,
    "ball 3.png": `${S3_BASE_URL}/${path}/ball 3.png`,
    "ball 4.png": `${S3_BASE_URL}/${path}/ball 4.png`,
    "ball-4 1.png": `${S3_BASE_URL}/${path}/ball-4 1.png`,
    "banner_overlay_full_height_green.png": `${S3_BASE_URL}/${path}/banner_overlay_full_height_green.png`,
    "basketball-vector-graphics.png": `${S3_BASE_URL}/${path}/basketball-vector-graphics.png`,
    "board games.png": `${S3_BASE_URL}/${path}/board games.png`,
    "Calendar.png": `${S3_BASE_URL}/${path}/Calendar.png`,
    "card icons.png": `${S3_BASE_URL}/${path}/card icons.png`,
    "doodl-5 1.png": `${S3_BASE_URL}/${path}/doodl-5 1.png`,
    "Facebook.png": `${S3_BASE_URL}/${path}/Facebook.png`,
    "Frame 39554.png": `${S3_BASE_URL}/${path}/Frame 39554.png`,
    "Group.png": `${S3_BASE_URL}/${path}/Group.png`,
    "Icon.png": `${S3_BASE_URL}/${path}/Icon.png`,
    "Image (1).png": `${S3_BASE_URL}/${path}/Image (1).png`,
    "Image (2).png": `${S3_BASE_URL}/${path}/Image (2).png`,
    "Image (3).png": `${S3_BASE_URL}/${path}/Image (3).png`,
    "Image (4).png": `${S3_BASE_URL}/${path}/Image (4).png`,
    "Image (5).png": `${S3_BASE_URL}/${path}/Image (5).png`,
    "Image (6).png": `${S3_BASE_URL}/${path}/Image (6).png`,
    "Image (7).png": `${S3_BASE_URL}/${path}/Image (7).png`,
    "Image (8).png": `${S3_BASE_URL}/${path}/Image (8).png`,
    "Image (9).png": `${S3_BASE_URL}/${path}/Image (9).png`,
    "image 15.png": `${S3_BASE_URL}/${path}/image 15.png`,
    "image 16.png": `${S3_BASE_URL}/${path}/image 16.png`,
    "image 17.png": `${S3_BASE_URL}/${path}/image 17.png`,
    "image 18.png": `${S3_BASE_URL}/${path}/image 18.png`,
    "img-4 1.png": `${S3_BASE_URL}/${path}/img-4 1.png`,
    "Instagram.png": `${S3_BASE_URL}/${path}/Instagram.png`,
    "instagram-ico.png": `${S3_BASE_URL}/${path}/instagram-ico.png`,
    "Layer_1 (1).png": `${S3_BASE_URL}/${path}/Layer_1 (1).png`,
    "Layer_1.png": `${S3_BASE_URL}/${path}/Layer_1.png`,
    "Layer_2.png": `${S3_BASE_URL}/${path}/Layer_2.png`,
    "Layer_3.png": `${S3_BASE_URL}/${path}/Layer_3.png`,
    "Layer_4.png": `${S3_BASE_URL}/${path}/Layer_4.png`,
    "LinkedIn.png": `${S3_BASE_URL}/${path}/LinkedIn.png`,
    "logo.png": `${S3_BASE_URL}/${path}/logo.png`,
    "playstation.png": `${S3_BASE_URL}/${path}/playstation.png`,
    "playstation-4-logo-svg-4.png": `${S3_BASE_URL}/${path}/playstation-4-logo-svg-4.png`,
    "Playzo.png": `${S3_BASE_URL}/${path}/Playzo.png`,
    "Rectangle 12.png": `${S3_BASE_URL}/${path}/Rectangle 12.png`,
    "Rectangle 67.png": `${S3_BASE_URL}/${path}/Rectangle 67.png`,
    "Rectangle 669.png": `${S3_BASE_URL}/${path}/Rectangle 669.png`,
    "Rectangle 673.png": `${S3_BASE_URL}/${path}/Rectangle 673.png`,
    "Rectangle 677.png": `${S3_BASE_URL}/${path}/Rectangle 677.png`,
    "Rectangle 678.png": `${S3_BASE_URL}/${path}/Rectangle 678.png`,
    "Rectangle 679.png": `${S3_BASE_URL}/${path}/Rectangle 679.png`,
    "Rectangle 685 (1).png": `${S3_BASE_URL}/${path}/Rectangle 685 (1).png`,
    "Rectangle 685 (2).png": `${S3_BASE_URL}/${path}/Rectangle 685 (2).png`,
    "Rectangle 685 (3).png": `${S3_BASE_URL}/${path}/Rectangle 685 (3).png`,
    "Rectangle 685 (4).png": `${S3_BASE_URL}/${path}/Rectangle 685 (4).png`,
    "Rectangle 685 (5).png": `${S3_BASE_URL}/${path}/Rectangle 685 (5).png`,
    "Rectangle 685 (6).png": `${S3_BASE_URL}/${path}/Rectangle 685 (6).png`,
    "Rectangle 685 (7).png": `${S3_BASE_URL}/${path}/Rectangle 685 (7).png`,
    "Rectangle 685 (8).png": `${S3_BASE_URL}/${path}/Rectangle 685 (8).png`,
    "Rectangle 685 (9).png": `${S3_BASE_URL}/${path}/Rectangle 685 (9).png`,
    "Rectangle 685 (11).png":  `${S3_BASE_URL}/${path}/Rectangle 685 (11).png`,
    "Rectangle 685 (12).png": `${S3_BASE_URL}/${path}/Rectangle 685 (12).png`,
    "Rectangle 685.png": `${S3_BASE_URL}/${path}/Rectangle 685.png`,
    "tennis-racket-equipment-svg-png-icon-download-32.png": `${S3_BASE_URL}/${path}/tennis-racket-equipment-svg-png-icon-download-32.png`,
    "turf.png": `${S3_BASE_URL}/${path}/turf.png`,
    "Twitter.png": `${S3_BASE_URL}/${path}/Twitter.png`,
    "Variant10.png": `${S3_BASE_URL}/${path}/Variant10.png`,
    "Vector (1).png": `${S3_BASE_URL}/${path}/Vector (1).png`,
    "Vector (2).png": `${S3_BASE_URL}/${path}/Vector (2).png`,
    "Vector.png": `${S3_BASE_URL}/${path}/Vector.png`,
    "YouTube.png": `${S3_BASE_URL}/${path}/YouTube.png`,
    "Inter-Black.png": `${S3_BASE_URL}/${path}/Inter-Black.png`,
    // "Inter-VariableFont_slnt,wght.ttf": `${S3_BASE_URL}/${path}/Inter-VariableFont_slnt,wght.ttf`,
    "ball-306073_960_720.webp": `${S3_BASE_URL}/${path}/ball-306073_960_720.webp`,
    "cricket-game-player-playing-007-1024.webp": `${S3_BASE_URL}/${path}/cricket-game-player-playing-007-1024.webp`,
    "pexels-photo-187329.webp": `${S3_BASE_URL}/${path}/pexels-photo-187329.webp`,
    "pexels-photo-357514.webp": `${S3_BASE_URL}/${path}/pexels-photo-357514.webp`,
    "pexels-photo-2202685.webp": `${S3_BASE_URL}/${path}/pexels-photo-2202685.webp`,
    "pexels-photo-8007500.webp":  `${S3_BASE_URL}/${path}/pexels-photo-8007500.webp`,
    "pexels-photo-10544231.webp": `${S3_BASE_URL}/${path}/pexels-photo-10544231.webp`,
    "soccer_ball-14-512.webp": `${S3_BASE_URL}/${path}/soccer_ball-14-512.webp`,
}

export default assets